class ViewSDKClient {
  url = null;

  constructor({ url }) {
    this.url = url;

    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;

  }

  ready() {

    return this.readyPromise;
  }

  previewFile(divId, viewerConfig, file_id = "1") {
    const config = {
      /* Pass your registered client id */
      //   clientId: "fd18d73c8f284e93b4c2bd4a6af065ef",
      clientId: process.env.REACT_APP_ADOBE_EMBED_KEY,
    };

    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);
    console.log(this.url);
    debugger;
    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* Location of file where it is hosted */
          location: {
            url: this.url,
            // url: "http://0.0.0.0:8000/project_file/695f53f2-ee43-4899-8762-0a5520bbb5e4/download/",

            /*
                    If the file URL requires some additional headers, then it can be passed as follows:-
                    headers: [
                        {
                            key: "<HEADER_KEY>",
                            value: "<HEADER_VALUE>",
                        }
                    ]
                    */
          },
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: "Bodea Brochure.pdf",
          /* file ID */
          id: file_id,
        },
      },
      viewerConfig
    );

    return previewFilePromise;
  }

  previewFileUsingFilePromise(
    divId,
    filePromise,
    fileName,
    file_id = "1",
    viewerConfig = {}
  ) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View({
      /* Pass your registered client id */
      //   clientId: "fd18d73c8f284e93b4c2bd4a6af065ef",
      clientId: process.env.REACT_APP_ADOBE_EMBED_KEY,

      /* Pass the div id in which PDF should be rendered */
      divId,
    });
    console.log("process.env.ADOBE_EMBED_KEY", process.env.REACT_APP_ADOBE_EMBED_KEY);
    /* Invoke the file preview API on Adobe DC View object */
    return this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* pass file promise which resolve to arrayBuffer */
          promise: filePromise,
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: fileName,
          id: file_id,
        },
      },
      viewerConfig
    );
  }

  //   registerSaveApiHandler() {
  //     /* Define Save API Handler */
  //     const saveApiHandler = (metaData, content, options) => {
  //       console.log(metaData, content, options);
  //       return new Promise((resolve) => {
  //         /* Dummy implementation of Save API, replace with your business logic */
  //         setTimeout(() => {
  //           const response = {
  //             code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
  //             data: {
  //               metaData: Object.assign(metaData, {
  //                 updatedAt: new Date().getTime(),
  //               }),
  //             },
  //           };
  //           resolve(response);
  //         }, 2000);
  //       });
  //     };

  //     this.adobeDCView.registerCallback(
  //       window.AdobeDC.View.Enum.CallbackType.SAVE_API,
  //       saveApiHandler,
  //       {}
  //     );
  //   }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    // this.adobeDCView.registerCallback(
    //   /* Type of call back */
    //   window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
    //   /* call back function */
    //   (event) => {
    //     console.log(event);
    //   },
    //   /* options to control the callback execution */
    //   {
    //     /* Enable PDF analytics events on user interaction. */
    //     enablePDFAnalytics: true,
    //   }
    // );
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      (event) => {
        console.log(event);
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        showSaveButton: false,
      }
    );
  }
}

export default ViewSDKClient;
