import React from "react";
import { Link } from "react-router-dom";
import { AddIcon } from "../../components/Icons.jsx";
import ProjectItem from "./ProjectItem.jsx";
import "./Projects.css";
import { useQuery } from "react-query";
import { ProjectService } from "../../services/dataService.js";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {Typography} from "antd";

export default function Projects() {
  const projects = useQuery("projects", () => ProjectService.getAll());
  const isLoading = projects.isLoading;

  if (isLoading) return;

  return (
    <div className="projects">

      <div className="header">
        <Typography.Title level={1}>Projects</Typography.Title>

        <Link to={`/projects/newProject`} className="button secondary">
          <Button type="link">
            {" "}
            <PlusOutlined /> Create Project
          </Button>
        </Link>
      </div>

      {projects.data?.results?.length ? (
        <div className="projects-list">
          {projects.data?.results.map((project) => (
            <ProjectItem key={project._id || project.id} project={project} />
          ))}
        </div>
      ) : (
        <div className="item empty-projects">
          <p>
            No projects created. Click in the{" "}
            <Link to={`/projects/newProject`}>Create Project</Link> button to
            add new project.
          </p>
        </div>
      )}
    </div>
  );
}
