import React, { Fragment } from "react";
import "./Help.css";
import { Typography } from "antd";

/**
 * 
 * @returns 1

2

HOW TO USE THIS APP
Our Mission
Welcome to School Leaders Advantage (SLA), the very first artificial intelligence (AI) tool 
developed exclusively for principals, vice principals, superintendents, school board members – 
and other educators or administrators directly or indirectly involved with educational leadership 
in elementary and secondary schools.
The result of many years of development preceding today’s AI revolution, our unique Patent 
Pending application connects school leadership standards with research-based best practices in 
the form of reports, studies, articles journals, and websites many of which have been peer-
reviewed. No other tool in the Educational Technology marketplace makes the standards so 
relevant and, most importantly, so actionable.
SLA is based on the sound pedagogical concept of applied knowledge – the standards featured 
in this app, either proprietary or state-specific, provide a conceptual framework for exemplary 
educational leadership. The resources featured in SLA offer a valuable toolkit for achieving 
standards-related goals on a daily basis, dramatically improving learning outcomes for every 
student!
How to Use School Leaders Advantage
After logging in, SLA’s Features page and menu bar provide a variety of useful, efficient search 
options. The “Chat with SLA” option allows users to ask school-leadership-specific questions to 
get immediate answers, preventing time-consuming and laborious research on traditional search 
engines. Besides providing informative results, this option also links users to related, evidence-
based publications, which can be accessed using the associated hyperlinks or by clicking on the 
app’s Resource tab.
The ”Explore Standards” feature offers multiple search and recommendation options. The 
“Statements” option opens to SLA’s proprietary, internally-created Standards. From the feature, 
users can either click on related school leadership tasks or click on the Chat button for a more 
interactive experience. The Chat button for each statement provides a step-by-step guide for 
implementing that statement in your building.
From the Menu Bar, users can also select school leadership standards from their particular state
in cases where their locality does not employ the Professional Standards for Educational Leaders
(PSEL), formerly known as the Interstate School Leaders Consortium (ISLLC) Framework. Users 
in states governed by the PSEL or state-specific aligned standards, or any other school 
leadership standards (e.g., National Educational Leadership Preparation [NELP] framework) can 
cut and paste the specific standards statement into the Query Bar provided to get the 
information sought.
The “View Resources” button opens to several options, i.e., Standards-Aligned Resources, 
General Resources, Web Resources and PDFs saved to your account (i.e., the “Your Content” 
option).
The  “ITEM” (Implementation, Tracking and Evaluation Module) button on the Dashboard and 
“Open ITEM” button within the search results open a Project Management Tool supporting the 
application of best practices in your school, allowing users to create,  open and edit existing 
projects.
The “Your Documents” button lets users upload their own PDFs related to educational leadership
and school administration.  SLA handles those documents in the same fashion as the Standards 
Searches but without the Chat function.
The Inspired School Solutions Team hopes that you find SLA beneficial to your school 
communities. Please feel free to provide us with feedback at info@inspiredschoolsolutions.com. 
Thank you for using our product.
 */
export default function Help() {
  return (
    <Fragment>
      <main
        className="wrapper help"
        style={{
          marginTop: "20px",
        }}
      >
        <div className="header">
          <Typography.Title level={3}>
            How to Use the School Leaders Advantage © App
          </Typography.Title>
        </div>

        <div className="item">
          <Typography.Title level={4}>Our Mission</Typography.Title>

          <Typography.Text>
            Welcome to School Leaders Advantage (SLA), the very first artificial
            intelligence (AI) tool developed exclusively for principals, vice
            principals, superintendents, school board members – and other
            educators or administrators directly or indirectly involved with
            educational leadership in elementary and secondary schools.
          </Typography.Text>
          <br />
          <Typography.Text>
            The result of many years of development preceding today’s AI
            revolution, our unique Patent Pending application connects school
            leadership standards with research-based best practices in the form
            of reports, studies, articles journals, and websites many of which
            have been peer- reviewed. No other tool in the Educational
            Technology marketplace makes the standards so relevant and, most
            importantly, so <b>actionable</b>.
          </Typography.Text>
          <br />
          <Typography.Text>
            SLA is based on the sound pedagogical concept of{" "}
            <b>applied knowledge</b> – the standards featured in this app,
            either proprietary or state-specific, provide a conceptual framework
            for exemplary educational leadership. The resources featured in SLA
            offer a valuable toolkit for achieving standards-related goals on a
            daily basis, dramatically improving learning outcomes for every
            student!
          </Typography.Text>

          <br />

          <Typography.Title level={4}>
            How to Use School Leaders Advantage
          </Typography.Title>
          <Typography.Text>
            After logging in, SLA’s Features page and menu bar provide a variety
            of useful, efficient search options. The “Chat with SLA” option
            allows users to ask school-leadership-specific questions to get
            immediate answers, preventing time-consuming and laborious research
            on traditional search engines. Besides providing informative
            results, this option also links users to related, evidence- based
            publications, which can be accessed using the associated hyperlinks
            or by clicking on the app’s Resource tab.
          </Typography.Text>

          <br />
          <Typography.Text>
            The ”Explore Standards” feature offers multiple search and
            recommendation options. The “Statements” option opens to SLA’s
            proprietary, internally-created Standards. From the feature, users
            can either click on related school leadership tasks or click on the
            Chat button for a more interactive experience. The Chat button for
            each statement provides a step-by-step guide for implementing that
            statement in your building.
          </Typography.Text>

          <br />
          <Typography.Text>
            From the Menu Bar, users can also select school leadership standards
            from their particular state in cases where their locality does not
            employ the Professional Standards for Educational Leaders (PSEL),
            formerly known as the Interstate School Leaders Consortium (ISLLC)
            Framework. Users in states governed by the PSEL or state-specific
            aligned standards, or any other school leadership standards (e.g.,
            National Educational Leadership Preparation [NELP] framework) can
            cut and paste the specific standards statement into the Query Bar
            provided to get the information sought.
          </Typography.Text>

          <br />
          <Typography.Text>
            The “View Resources” button opens to several options, i.e.,
            Standards-Aligned Resources, General Resources, Web Resources and
            PDFs saved to your account (i.e., the “Your Content” option).
          </Typography.Text>

          <br />
          <Typography.Text>
            The “ITEM” (Implementation, Tracking and Evaluation Module) button
            on the Dashboard and “Open ITEM” button within the search results
            open a Project Management Tool supporting the application of best
            practices in your school, allowing users to create, open and edit
            existing projects.
          </Typography.Text>
          <br />
          <Typography.Text>
            The “Your Documents” button lets users upload their own PDFs related
            to educational leadership and school administration. SLA handles
            those documents in the same fashion as the Standards Searches but
            without the Chat function.
          </Typography.Text>
          <br />
          <Typography.Text>
            The Inspired School Solutions Team hopes that you find SLA
            beneficial to your school communities. Please feel free to provide
            us with feedback at
            <a href="mailto:info@inspiredschoolsolutions.com">
              info@inspiredschoolsolutions.com
            </a>{" "}
            Thank you.
          </Typography.Text>

          {/* <p>Thank you for purchasing a subscription to School Leaders Advantage (SLA), developed by Inspired School Solutions. This app has been created for educational leaders and school administrators of Kindergarten to Grade 12 schools and is the first online resource using artificial intelligence (AI) to link best practices in school leadership with industry-aligned performance standards.</p>
          <p>After logging into our tool, the first feature available will be our company’s proprietary set of school leadership standards. A set of corresponding statements detailing related best practices accompanies each set of categorical standards. Clicking on any of those statements will provide users with a set of resources (articles, papers, studies, and research, all provided in PDF format) selected using artificial intelligence-driven algorithms. Those resources provide specific guidance on systemic school improvement strategies that can be implemented at the building level.</p>
          <p>Another key feature allows users in some parts of the Nation to access resources aligned with the specific school leadership standards for their State. Available regions can be found in a drop-down menu. If your State does not appear in the drop-down menu, the particular standard can be entered into a Search Bar, and SLA will find the corresponding best practices.</p>
          <p> All recommended resources can be bookmarked for future reference.</p>
          <p>SLA also includes a unique proprietary instrument called the Implementation, Tracking and Evaluation Module© (ITEM), a special Project Management tool that allows you to apply a chosen best practice in your school. Usingthe ITEM, you can define specific strategies, milestones, and expected outcomes while also delegating tasks and recording outcomes. In this way, SLA helps you create and monitor a research-based "roadmap" to meaningful, lasting school improvement.</p>
          <p>Also, if you have a specific question about school leadership not related to the pillars and standards, type them into our “Knowledge Bot” in the upper right-hand side of the app's toolbar, and SLA will work its AI-magic to provide the best answers.</p>
          <p>We genuinely hope SLA can play a substantial role in ensuring that all your students succeed and achieve the highest possible levels of elementary and secondary scholarship. We look forward to serving as your personal research assistant for years to come.</p>
          <p>Please feel free to provide feedback on SLA by writing to us at <a href="mailto:info@inspiredschoolsolutions.com" target="_blank" rel="noopener">info@inspiredschoolsolutions.com</a>.</p>

          <p>Onward,</p>
          <p>The Founders</p> */}
        </div>
      </main>
    </Fragment>
  );
}
