import {
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Typography,
  Upload,
  message,
  Input,
} from "antd";
import { DocService } from "../../services/dataService";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";

const ContentManagement = () => {
  const [state, setState] = useState({
    page: 1,
    query: "",
    newDocFlag: false,
  });

  const queryClient = useQueryClient();

  const doc = useQuery(["doc", state.page, state.query], () =>
    DocService.getAll({ page: state.page, limit: 10, query: state.query })
  );

  const downloadDocMutation = useMutation((id) => DocService.downloadDoc(id), {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.setAttribute("href", url)
      link.setAttribute("download", `download.pdf`)
      // Open the link in a new tab
      // window.open(url, '_blank');
      // Trigger the download
      link.click();
    },
  });

  const deleteDocMutation = useMutation((id) => DocService.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("doc");
      message.success("Document deleted successfully");
    },
  });

  const uploadFileMutation = useMutation((data) => DocService.uploadDoc(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("doc");
      message.success("Document uploaded successfully");
    },
  });

  const handleUpload = (file) => {
    // Save to state
    setState({
      ...state,
      file,
      fileList: [{ uid: "-1", name: file.name, status: "done" }],
    });

    // Upload file

    // uploadFileMutation.mutate(formData);
    return false;
  };

  return (
    <div>
      <Modal
        title="Add new document"
        open={state.newDocFlag}
        footer={null}
        onCancel={() => setState({ ...state, 
          newDocFlag: false
         })}
      >
        <Upload.Dragger
          listType="text"
          beforeUpload={handleUpload}
          action={null}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <Button
          style={{ marginTop: 16 }}
          onClick={() => {
            const formData = new FormData();
            formData.append("file", state.file);
            
            uploadFileMutation.mutate(formData);

          }}
          loading={uploadFileMutation.isLoading}
        >
          Save
        </Button>
      </Modal>
      <Row justify="space-between">
        <Col>
          {" "}
          {/* <Typography.Title level={4}>Content Management</Typography.Title> */}
          <Input.Search
            placeholder="Search"
            style={{ width: "400px" }}
            onSearch={(value) => {
              setState({ ...state, query: value });
            }}
          />
        </Col>

        <Col>
          <Button
            type="text"
            onClick={() => {
              setState({ ...state, newDocFlag: true });
            }}
          >
            <PlusOutlined /> Add document
          </Button>
        </Col>
      </Row>
      <br />

      <List
        dataSource={doc.data?.results}
        loading={doc.isLoading}
        pagination={{
          current: state.page,
          pageSize: 10,
          showSizeChanger: false,
          total: doc.data?.total,
          onChange: (page) => {
            setState({ ...state, page });
          },
        }}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <Typography.Link
              onClick={() => {
                downloadDocMutation.mutate(item._id);
              }}
            >
              {item.openai_summary?.title || item.name || item.title} 
            </Typography.Link>
            <Button
              type="text"
              danger
              onClick={() => {
                deleteDocMutation.mutate(item._id);
              }}
              loading={
                deleteDocMutation.isLoading &&
                deleteDocMutation.variables === item._id
              }
            >
              Delete
            </Button>
          </List.Item>
        )}
      ></List>
    </div>
  );
};

export default ContentManagement;
