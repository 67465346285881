import {
    Button,
    Card,
    Col,
    List,
    Modal,
    Row,
    Typography,
    Upload,
    message,
    Input,
  } from "antd";
  import { DocService } from "../../services/dataService";
  import { useState } from "react";
  import { useMutation, useQuery, useQueryClient } from "react-query";
  import { Link } from "react-router-dom";
  import { InboxOutlined , PlusOutlined} from "@ant-design/icons";
  
  const OtherArticles = () => {
    const [state, setState] = useState({
      page: 1,
      newDocFlag: false,
    });
  
    const queryClient = useQueryClient();
  
    const doc = useQuery(["article", state.page], () => DocService.getArticles(
      {page: state.page, limit: 10}
    ));
  
    // const deleteDocMutation = useMutation((id) => DocService.delete(id), {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries("doc");
    //     message.success("Document deleted successfully");
    //   },
    // });
  
    const uploadFileMutation = useMutation((data) => DocService.uploadDoc(data), {
      onSuccess: () => {
        queryClient.invalidateQueries("doc");
        message.success("Document uploaded successfully");
      },
    });
  
    const handleUpload = (file) => {
      // Save to state
      setState({
        ...state,
        file,
        fileList: [{ uid: "-1", name: file.name, status: "done" }],
      });
  
      // Upload file
  
      // uploadFileMutation.mutate(formData);
      return false;
    };
  
    return (
      <div>
        <Modal
          title="Add new document"
          open={state.newDocFlag}
          footer={null}
          onCancel={() => setState({ ...state, newDocFlag: false })}
        >
          <Upload.Dragger
            listType="text"
            beforeUpload={handleUpload}
            action={null}
            multiple={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
  
          <Button
            style={{ marginTop: 16 }}
            onClick={() => {
              const formData = new FormData();
              formData.append("file", state.file);
              uploadFileMutation.mutate(formData);
            }}
            loading={uploadFileMutation.isLoading}
          >
            Save
          </Button>
        </Modal>
        {/* <Row justify="space-between">
          <Col>
            {" "}
            <Input.Search placeholder="Search"   style={{width: "400px"}}/>
          </Col>
  
          <Col>
            <Button
              type="text"
              onClick={() => {
                setState({ ...state, newDocFlag: true });
              }}
            >
              <PlusOutlined/> Add document
            </Button>
          </Col>
        </Row> */}
        <br />
  
        <List
          dataSource={doc.data?.results}
          loading={doc.isLoading}
          pagination={{
            current: state.page,
            pageSize: 10,
            showSizeChanger: false,
            total: doc.data?.total,
            onChange: (page) => {
              setState({ ...state, page });
            },
          }}
          renderItem={(item) => (
            <List.Item key={item._id}>
              <Link to={item.pdf_link} target="_blank">
                <Typography.Link>{item.title}</Typography.Link>
              </Link>
              <Button
                type="text"
                // onClick={() => {
                //   deleteDocMutation.mutate(item._id);
                // }}
                // loading={
                //   deleteDocMutation.isLoading &&
                //   deleteDocMutation.variables === item._id
                // }
              >
                Move to Pro
              </Button>
            </List.Item>
          )}
        ></List>
      </div>
    );
  };
  
  export default OtherArticles;
  