import React, { Component } from "react";
import ViewSDKClient from "./viewSDKClient";
import { transformAnnotation } from "./handleAnnotations";

class SizedContainer extends Component {
  viewerConfig = {
    /* Enable commenting APIs */
    enableAnnotationAPIs: false /* Default value is false */,
    showSaveButton: false,
    showLeftHandPanel: false,
    // enableLinearization: true,
    // showAnnotationTools: false,
    /* Include existing PDF annotations and save new annotations to PDF buffer */
    includePDFAnnotations: false /* Default value is false */,
    showCommentsPanel: false,
    showToolbar: false,
    showToolsOnTextSelection: false,
    showAnnotationTools: false,
    annotationUIConfig: {
      showSaveButton: false,
      showToolbar: false,
      showCommentsPanel: false,
      showToolsOnTextSelection: false,
    },
  };
  viewSDKClient = null;
  annotations = null;
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  componentDidMount() {
    // this.annotations = this.props.annotations.map((item) =>
    //   transformAnnotation(item, this.props.fileId)
    // );

    this.viewSDKClient = new ViewSDKClient({
      url: this.props.url,
      ...this.viewerConfig,
    });
    console.log(this.viewSDKClient.ready());
    this.viewSDKClient.ready().then(() => {
      if (this.props.url) {
        var fileName = this.props.fileName;
        var request = new XMLHttpRequest();
        request.open("GET", this.props.url, true);
        request.responseType = "blob";
        request.onload = () => this.onFileDownloadComplete(request.response);
        request.send();
      } else {
        this.props.dataLoader().then((response) => {
          this.onFileDownloadComplete(response);
        });
      }
      // this.props.dataLoader().then((response) => {
      //   this.onFileDownloadComplete(response);
      // });
      // var fileName = this.props.fileName;
      // var request = new XMLHttpRequest();
      // request.open("GET", this.props.url, true);
      // request.responseType = "blob";
      // request.onload = () => this.onFileDownloadComplete(request.response);
      // request.send();
      /* Invoke file preview */
      //   viewSDKClient.previewFile("pdf-div", {
      //     /* Pass the embed mode option here */
      //     embedMode: "SIZED_CONTAINER",
      //   });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.url !== prevProps.url) {
      this.viewSDKClient = new ViewSDKClient({
        url: this.props.url,
        ...this.viewerConfig,
      });
      console.log(this.viewSDKClient.ready());
      this.viewSDKClient.ready().then(() => {
        if (this.props.url) {
          var fileName = this.props.fileName;
          var request = new XMLHttpRequest();
          request.open("GET", this.props.url, true);
          request.responseType = "blob";
          request.onload = () => this.onFileDownloadComplete(request.response);
          request.send();
        } else {
          this.props.dataLoader().then((response) => {
            this.onFileDownloadComplete(response);
          });
        }
        // var fileName = this.props.fileName;
        // var request = new XMLHttpRequest();
        // request.open("GET", this.props.url, true);
        // request.responseType = "blob";
        // request.onload = () => this.onFileDownloadComplete(request.response);
        // request.send();
        /* Invoke file preview */
        //   viewSDKClient.previewFile("pdf-div", {
        //     /* Pass the embed mode option here */
        //     embedMode: "SIZED_CONTAINER",
        //   });
      });
      this.annotations = this.props.annotations.map((item) =>
        transformAnnotation(item, this.props.fileId)
      );
    }
    //
    if (
      JSON.stringify(prevProps.annotations) !==
      JSON.stringify(this.props.annotations)
    ) {
      const newAnnotations = this.props.annotations
        .filter(
          (item) =>
            !(this.state.annotationDict && this.state.annotationDict[item.id])
        )
        .map((item) => transformAnnotation(item, this.props.fileId));
      this.state.adobeViewer.getAnnotationManager().then((annotationManager) =>
        annotationManager
          .addAnnotationsInPDF(newAnnotations)
          .then((result) => {
            var annotationDict = {};
            newAnnotations.forEach((element) => {
              annotationDict[element.id] = annotationDict;
            });
            this.setState((prev) => ({
              ...prev,
              annotationDict: {
                ...(this.state.annotationDict || {}),
                ...annotationDict,
              },
            }));
            console.log(
              "Annotations added to PDF successfully and updated PDF buffer returned."
              // result
            );
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }

    // Check if new location has been updated
    if (
      this.props.goTo &&
      JSON.stringify(prevProps.goTo) !== JSON.stringify(this.props.goTo)
    ) {
      this.state.adobeViewer.getAPIs().then((apis) => {
        console.log(this.props.goTo);
        apis
          .gotoLocation(
            this.props.goTo.page,
            this.props.goTo.X,
            this.props.goTo.Y
          )
          .then(() => console.log("Success"))
          .catch((error) => console.log(error));
      });
    }
  }

  onFileDownloadComplete = (e) => {
    var reader = new FileReader();
    reader.onloadend = (e) => {
      const filePromise = Promise.resolve(e.target.result);
      /* Helper function to render the file using PDF Embed API. */
      const somePromise = this.viewSDKClient.previewFileUsingFilePromise(
        "pdf-div",
        filePromise,
        this.props.fileName,
        this.props.fileId,
        this.viewerConfig
      );
      somePromise.then((adobeViewer) => {
        adobeViewer.getAnnotationManager().then((annotationManager) => {
          /* API to add annotations to PDF and return the updated PDF buffer */
          /* These APIs will work only when includePDFAnnotations is set to true in viewerConfig */
          this.setState((prev) => ({
            ...prev,
            annotationManager: annotationManager,
            adobeViewer: adobeViewer,
          }));

          annotationManager
            .addAnnotationsInPDF(this.annotations)
            .then((result) => {
              var annotationDict = {};
              this.annotations.forEach((element) => {
                annotationDict[element.id] = annotationDict;
              });
              this.setState((prev) => ({
                ...prev,
                annotationDict: annotationDict,
              }));
              console.log(
                "Annotations added to PDF successfully and updated PDF buffer returned.",
                result
              );
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
      this.viewSDKClient.registerEventsHandler();
    };
    if (e?.data) {
      reader.readAsArrayBuffer(e.data);

    }else{
      reader.readAsArrayBuffer(e);

    }
  };

  render() {
    return <div id="pdf-div" className="sized-container-div" />;
  }
}

export default SizedContainer;
