import { Button, Modal } from "antd";
import React from "react";
import { BotService, StandardService } from "../../services/dataService";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MessageOutlined, FileDoneOutlined } from "@ant-design/icons";

const QuickStandard = ({ standard, description, id, chatHandover }) => {
  return (
    <div className="group text-sm  border border-gray-100 bg-white rounded-lg p-3 transition hover:border-endeavour-300  cursor-pointer py-4 px-4 h-64 flex flex-col justify-between">
      <div className="text-gray-600 text-left uppercase tracking-wider	text-xs mb-2 font-semibold h-10">
        {standard}
      </div>
      <div className="text-gray-500 h-24">{description}</div>

      <div className="flex justify-end mt-4">
        <div>
          <Link to={`/standards/${id}`}>
            <button
              className="rounded-lg bg-white text-endeavour-500 px-2 py-1
        mt-1 py-1 transition ml-auto mt-2 mr-2 hover:bg-endeavour-100"
            >
              <FileDoneOutlined /> Statements
            </button>
          </Link>

          <button
            onClick={() => chatHandover(description)}
            className=" rounded-lg bg-endeavour-500 text-white px-2 py-1
       mt-1 py-1 transition ml-auto mt-2 hover:bg-endeavour-400"
          >
            <MessageOutlined /> Chat
          </button>
        </div>
      </div>
    </div>
  );
};
const ChatV2 = () => {
  const [message, setMessage] = React.useState("");
  const navigate = useNavigate();
  const sessions = useQuery("sessions", () => BotService.getSessions());
  const [showChatHistory, setShowChatHistory] = React.useState(false);
  const standards = useQuery(
    ["standards"],
    () =>
      StandardService.getAll({
        state: null,
      }),
    {
      enabled: true,
    }
  );
  const chatHandover = async (text) => {
    // Create a new session
    let res = await BotService.createChatSession();

    navigate(`/chat/${res.id}`, {
      state: {
        sessionId: res.id,
        firstMessage: message || text,
      },
    });
  };

  return (
    <div
      className="h-full"
      style={{
        height: "calc(100vh - 176px)",
      }}
    >
      <Modal
        title="Chat History"
        visible={showChatHistory}
        onOk={() => {}}
        onCancel={() => setShowChatHistory(false)}
        footer={null}
      >
        <div className="flex flex-col gap-2 h-[50vh] overflow-auto">
          {sessions.data &&
            sessions.data.results.map((chat, index) => {
              return (
                <div
                  key={index}
                  className="rounded-xl bg-gray-50 border border-gray-200 px-4 py-2 mx-2 my-2 basis-1/4 cursor-pointer"
                  onClick={() => navigate(`/chat/${chat.id}`)}
                >
                  <div className="text-sm text-gray-400 py-2 flex justify-between">
                    <div>
                      <div className="text-sm text-gray-600">
                        {chat.title || "Untitled"}
                      </div>
                      <div className="text-xs mt-2">
                        {moment(chat.created_at).fromNow()}
                      </div>
                    </div>
                    <div>
                      <button>
                        <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>

      <div className="flex flex-col h-full grow">
        {/* <div>
          <h1 className="text-xl text-endeavour-700">ChatV2</h1>
        </div> */}
        <div className="grow  flex flex-col justify-center items-center">
          <div className="max-w-[72rem] w-full">
            <div className="flex justify-between w-full">
              <h2 className="text-xl font-semibold text-endeavour-600 mb-6 text-left">
                Chat with SLA
              </h2>
              <Button
                type="text"
                className="text-gray-400"
                onClick={() => setShowChatHistory(true)}
              >
                Recent Chats
              </Button>
            </div>

            <div className="h-20 w-full relative">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  resize: "none",
                }}
                // className="shadow-md w-full p-2	rounded-xl border-2 border-endeavour-300  bg-endeavour-50"
                className="shadow-sm w-full p-4 pr-10	rounded-xl focus-visible:outline-none"
                placeholder="How can SLA help today?"
              />
              <div className="absolute right-4 top-4">
                <button className="bg-endeavour-500 text-white px-4 py-2 rounded-xl text-sm" onClick={() => chatHandover(message)}>
                  Send
                </button>
              </div>
            </div>
            <div className="text-gray-400 text-sm mx-2 mt-2">
              Ask your educational leadership or school administration question
              in the query box below, e.g. , “how do I improve communication
              between teachers and parents,” or “what are some best practices
              for conducting classroom walkthrough,” then click the Search
              symbol.
            </div>
            <div className="flex justify-between  mt-12">
              <h2 className="text-xl font-semibold text-endeavour-600">
                Explore Standards
              </h2>
              <div>
                <Button type="link" className="text-endeavour-400 font-semibold"
                onClick={
                  () => navigate("/standards")
                }
                >See all Standards and Related Statements here</Button>
              </div>
            </div>

            <div className="py-2 mx-2 my-2">
              <div className="text-sm text-gray-500 py-2 flex justify-between">
                <div>
                  Proprietary Standards to find answers and resources for other
                  areas of educational leadership and school administration.
                </div>
              </div>

              <div className=" px-2 my-4">
                <Slider
                  {...{
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }}
                >
                  {standards?.data?.results?.map((example, index) => {
                    return (
                      <div key={index}>
                        <div className="px-2">
                          <QuickStandard
                            chatHandover={(text) =>
                              chatHandover(`How to ${text}`)
                            }
                            id={example.id}
                            standard={example.title}
                            description={example.description}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>

        
        </div>
      </div>
    </div>
  );
};
export default ChatV2;
