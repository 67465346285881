import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Row } from "antd";

import PDFViewContainer from "./adobe/pdfRender";

const PDFDocumentView = ({
  id,
  title,
  url,
  dataLoader,
  highlightInPdf, 
  goTo,
}) => {
  const [state, setState] = useState({ loading: true });
  return (
    <>
      <div style={{ margin: "12px 0px", width: "100%", height:"100%" }}>
        {/* <Row> */}
          <PDFViewContainer
            fileId={id}
            fileName={title}
            // annotations={
            //   policyDetails.details?.policy_parsed_data?.highlights || []
            // }
            annotations={highlightInPdf|| []}
            dataLoader={dataLoader}
            url ={url}
            goTo={goTo}
          />
        {/* </Row> */}
      </div>
    </>
  );
};

export default PDFDocumentView;
