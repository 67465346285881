import React, { Fragment, useState } from "react";
import {
  Form,
  Row,
  Input,
  DatePicker,
  Button,
  Checkbox,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { dateFormat } from "../../../components/Functions";

function EvaluationPreview({ form, evaluation, field }) {
  return (
    <Fragment>
      <div className="preview-label">
        Feedback on intended outcome (please indicate if you met your goal).
      </div>
      <div className="preview-value">{evaluation.feedback}</div>
      <div className="preview-label">
        Please provide qualitative and/or quantitative data on the impact this
        practice had on the target audience and entire school
      </div>
      <div className="preview-value">{evaluation.data}</div>
      <div className="preview-label">
        Would you recommend this practice to other school leaders? Please
        indicate why or why not.
      </div>
      <div className="preview-value">{evaluation.recommendation}</div>
      <Form.Item
        valuePropName="checked"
        {...field}
        name={[field.name, "completed"]}
        label=""
        fieldKey={[field.fieldKey, "completed"]}
      >
        <Checkbox>Completed</Checkbox>
      </Form.Item>
    </Fragment>
  );
}

export const ProjectEvaluation = ({ form, fields, add, remove }) => {
  const [state, setState] = useState({
    updateItemIdx: null,
  });

  const addNewItem = () => {
    add({});
    setState({ ...state, updateItemIdx: fields.length });
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <h4>Project Evaluation</h4>
      <div style={{ marginBottom: "1rem" }}>
        <Typography.Text type="secondary">
          Please share with us your feedback about the implementation of this
          practice. Your input will help other school leaders to select
          effective practices and execute them in their buildings. Your insights
          will also help our team better understand what type of evidence-based
          practices our professional learning community is interested in. Thank
          you!
        </Typography.Text>
      </div>

      {fields.map((field, index) => (
        <div key={index} className="project-outcome-form">
          <Row
            // style={{ marginBottom: 8 }}
            align="middle"
          >
            <div style={{ color: "grey", marginBottom: ".5rem" }}>
              Evaluation #{index + 1}
            </div>
            <MinusCircleOutlined
              style={{ color: "red", marginLeft: "auto" }}
              onClick={() => {
                remove(field.name);
              }}
            />
          </Row>
          {/* <Row key={field.key} style={{ marginBottom: 8 }} align="middle"> */}
          {index == state.updateItemIdx ? (
            <>
              <Form.Item
                {...field}
                name={[field.name, "feedback"]}
                label="Feedback on intended outcome (please indicate if you met your goal)."
                fieldKey={[field.fieldKey, "feedback"]}
                rules={[{ required: true, message: "Please input feedback!" }]}
              >
                <Input placeholder="e.g. The outcome worked very well.." />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, "data"]}
                label="Please provide qualitative and/or quantitative data on the impact this practice had on the target audience and entire school."
                fieldKey={[field.fieldKey, "data"]}
                rules={[{ required: true, message: "Please input data!" }]}
              >
                <Input placeholder="e.g. In general this worked with the use..." />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, "recommendation"]}
                label="Would you recommend this practice to other school leaders? Please indicate why or why not."
                fieldKey={[field.fieldKey, "recommendation"]}
                rules={[
                  { required: true, message: "Please input recommendation!" },
                ]}
              >
                <Input placeholder="e.g. I would recommend this practice..." />
              </Form.Item>

              <Button
                size="small"
                onClick={() => {
                  // Validate fields to show error message

                  form
                    .validateFields(["evaluations"])
                    .then((values) => {
                      // debugger
                      console.log(values);
                      setState((prev) => ({ ...prev, updateItemIdx: null }));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Done
              </Button>
            </>
          ) : (
            <div key={index} className="project-outcome-preview">
              <EvaluationPreview
                evaluation={form.getFieldValue(["evaluations", field.name])}
                field={field}
              />
              {state.updateItemIdx == null && (
                <Button
                  size="small"
                  onClick={() =>
                    setState((prev) => ({ ...prev, updateItemIdx: index }))
                  }
                >
                  Edit
                </Button>
              )}
            </div>
          )}
        </div>
      ))}
      {state.updateItemIdx == null && (
        <Button
          type="dashed"
          onClick={addNewItem}
          block
          icon={<PlusOutlined />}
        >
          Add Evaluation
        </Button>
      )}
    </div>
  );
};
