import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { Meteor } from "meteor/meteor";
// import { useFind } from "meteor/react-meteor-data";
// import { Random } from "meteor/random";
// import { _ } from "meteor/underscore";
// import StateDropdown from "/imports/ui/standards/StateDropdown.jsx";

import {
  DocumentIcon,
  StarIcon,
  StarFullIcon,
} from "../../components/Icons.jsx";
import { useQueries, useQuery } from "react-query";
import { StandardService } from "../../services/dataService.js";

export function sliceText(string, max_length) {
  let result = string.substring(0, max_length);

  result = result.replace(/\n/g, "");
  result = result.replace(/\s+/g, " ").trim();
  // result = result.replace(/[^\w ]/, '');

  return result;
}

export default function Documents(props) {
  const navigate = useNavigate();
  const { subtitleId } = useParams();
  const { search } = useLocation();
  let searchText = new URLSearchParams(search).get("searchText");

  const [values, setValues] = useState({ documents: [], loading: false });

  const subtitle = useQuery(
    "subtitle",
    () => StandardService.getSubtitle(subtitleId),
    {
      enabled: subtitleId ? true : false,
    }
  );

  const documents = useQuery(
    ["documents", subtitleId, searchText],
    () =>
      StandardService.search(subtitleId, {
        search: (subtitle.data && subtitle.data.content) || searchText,
        limit: 10,
        offset: 0,
      }),
    {
      enabled:
        (subtitle.data && subtitle.data.content) || searchText ? true : false,
        staleTime: Infinity,
    }
  );


  const getPlaceholder = () => {
    let elements = [];
    for (let i = 0; i < 10; i++) {
      elements.push(<DocumentLoading key={`loading-${i}`} />);
    }
    return elements;
  };

  return (
    <div className="wrapper documents">
      <div className="header">
        <h1>Standard Documents</h1>
        <button onClick={() => navigate(-1)} className="button secondary">
          Back
        </button>
      </div>

      {/* {status.showCategoryDropdown && (
        <StateDropdown status={status} setStatus={setStatus} />
      )} */}

      <div className="documents-list">
        {documents.isLoading ? (
          <>{getPlaceholder()}</>
        ) : (
          documents.data?.value?.map((document) => (
            <DocumentItem
              key={document.metadata_storage_path}
              document={document}
            />
          ))
        )}
      </div>
    </div>
  );
}

function DocumentItem({ document }) {
  const navigate = useNavigate();

  const documentId = document.metadata_storage_path;
  const title = sliceText(document["@search.captions"][0].text, 120);
  //   const url = `${Meteor.settings.public.azure.blobUrl}/${document.metadata_storage_name}`;
  const description = sliceText(document.content, 350);

  //   const favorites = useFind(
  //     () =>
  //       FavoritesCollection.find({
  //         userId: Meteor.userId(),
  //         documentId: documentId,
  //       }),
  //     []
  //   );

  //   const favorite = favorites[0];

  const favorite = null;

  //   const handleCreateFavorite = () =>
  //     Meteor.call("favorite.insert", { documentId, title, description, url });

  //   const handleRemoveFavorite = () =>
  //     Meteor.call("favorite.remove", favorite._id);

  const handleCreateFavorite = () => {};

  const handleRemoveFavorite = () => {};

  //   const handleCreateItem = () => {
  //     navigate(`/project/${Random.id()}`, {
  //       state: { name: title, summary: description },
  //     });
  //   };

  const handleCreateItem = () => {};

  return (
    <div className="item document">
      <h4>{title}</h4>
      <p>{description}</p>

      <div className="actions">
        <a
          href={document.url}
          target="_blank"
          rel="noopener"
          className="button secondary"
        >
          Read More
        </a>
        <button
          type="button"
          onClick={handleCreateItem}
          className="button secondary"
        >
          Open in Item
        </button>

        {favorite ? (
          <button
            type="button"
            onClick={handleRemoveFavorite}
            className="button secondary favorite active"
          >
            <StarFullIcon />
          </button>
        ) : (
          <button
            type="button"
            onClick={handleCreateFavorite}
            className="button secondary favorite"
          >
            <StarIcon />
          </button>
        )}
      </div>
    </div>
  );
}

function DocumentLoading() {
  return (
    <div className="item loading">
      <div className="skeleton title-skeleton" />
      <div className="skeleton title-skeleton last" />

      <div className="skeleton content-skeleton" />
      <div className="skeleton content-skeleton" />
      <div className="skeleton content-skeleton" />
      <div className="skeleton content-skeleton last" />

      <div className="actions">
        <div className="skeleton button-skeleton" />
        <div className="skeleton button-skeleton" />
        <div className="skeleton button-skeleton favorite" />
      </div>
    </div>
  );
}
