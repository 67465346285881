import React, { Fragment } from 'react';
import './Legal.css';

export default function TermsService() {
  return (
    <Fragment>
      {/* <Navbar /> */}

      <main className="wrapper legal">
        <div className="header">
          <h1>Terms of Service (TOS)</h1>
        </div>

        <div className="item">
          <p>READ THESE TERMS OF SERVICE (COLLECTIVELY, THIS "AGREEMENT") CAREFULLY BEFORE USING ANY OF THE SERVICES PROVIDED TO END USERS AT OR THROUGH WWW.SCHOOLLEADERSADVANTAGE.COM, OR OTHERWISE OFFERED BY INSPIRED SCHOOL SOLUTIONS, INC. (OWNER OF WWW.SCHOOLLEADERSADVANTAGE.COM). BY USING THE SERVICES IN ANY MANNER, YOU, AND THE ENTITY OR ORGANIZATION YOU REPRESENT, IF ANY, (COLLECTIVELY, "YOU," "YOUR") AGREE THAT YOU HAVE READ AND AGREE TO BE BOUND BY AND A PARTY TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, TO THE EXCLUSION OF ALL OTHER TERMS. IF THE TERMS OF THIS AGREEMENT ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS. USE OF THE SERVICE IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL THE TERMS AND CONDITIONS OF THE AGREEMENT, NAVIGATE AWAY FROM THIS PAGE AND YOU WILL HAVE NO RIGHT TO USE THE SERVICES. QUESTIONS SHALL BE DIRECTED TO <a href="mailto:info@inspiredschoolsolutions.com" target="_blank" rel="noopener">info@inspiredschoolsolutions.com</a>.</p>

          <h2>Use of the Services</h2>
          <p>This Agreement includes rules and restrictions that govern access to and use of the technology and services offered by school leaders advantage.com (the "Services"). The term "Services" will include, without limitation, any service school leaders advantage.com performs for you and the technology offered by school leaders advantage.com. This Agreement and Services may be updated from time to time.</p>

          <h2>Access to Services</h2>
          <p>Subject to all terms and conditions of this Agreement, school leaders advantage.com grants you a limited, non-exclusive, non-sublicenseable, royalty-free, non-transferrable license to access and use the Services that you have selected in connection therewith, through the online registration and account maintenance processes ("Account Administration"), for Your internal, non-commercial, educational purposes. School leaders advantage.com reserves the right, in its sole discretion, to modify this Agreement at any time by posting a notice on the www.school leaders advantage.com website ("Website"), or by sending You a notice. You shall be responsible for reviewing and complying with any such modifications. Use of the Services following such notification constitutes Your acceptance of the terms and conditions of this Agreement as modified. You shall be responsible for acquiring and maintaining any equipment or ancillary services needed to connect to, access, or otherwise use the Services, including, without limitation, modems, hardware, software, and long distance or local telephone service. You shall be responsible for ensuring that such equipment or ancillary services are compatible with the Services.</p>

          <h2>Restrictions and Responsibilities</h2>
          <p>You will not, and will not permit any third party to: (a) make the Services of school leaders advantage.com available to anyone other than You, (b) reverse engineer the Services (to the extent such restriction is permitted by law), (c) interfere with or disrupt the integrity or performance of the Services, (d) attempt to provide unauthorized access to the Services, (e) frame or mirror any part of the Service, other than framing for Your own internal business purposes, (f) access the Services in order to build a competitive product or service, (g) modify, translate, or create derivative works based on the Services (for clarity, Reports, as defined below, created subject to all terms and conditions of this Agreement are not derivative works for the purpose of the foregoing prohibition), (h) sell, resell, license, sublicense, distribute, rent or lease the Services or include the Services in a service bureau or outsourcing offering; (i) use the Services in any manner that infringes or violates the intellectual property rights or proprietary rights of any third party; or (j) use the Services in any manner that is harmful, fraudulent, deceptive, threatening, abusive, obscene, libelous, or otherwise objectionable.</p>
          <p>You will (a) use commercially reasonable efforts to prevent unauthorized access to or use of the Services and notify school leaders advantage.com promptly of any such unauthorized access or use, (b) use the Services only in accordance with applicable laws and government regulations, (c) make available to school leaders advantage.com such personnel and information as may be reasonably required, and taking such other actions as school leaders advantage.com may reasonably request, (d) establish a username and password (or any other means required by school leaders advantage.com) for verifying that only Your designated employees have access to the Services, (e) be responsible for the security of your account, passwords, and files, and (f) be responsible for all uses of Your account with or without Your knowledge or consent.</p>
          <p>If You are a school, and not an individual user, You will designate an employee who will be responsible for all matters relating to this Agreement (Your "Primary Contact"). You may change the individual designated as Primary Contact at any time by providing written notice to school leaders advantage.com (which may be done through Account Administration).</p>

          <h2>Intelectual Property Rights: Confidentiality</h2>
          <p>Except as expressly set forth herein, school leaders advantage.com (and its licensors, where applicable) will retain all intellectual property rights relating to the Services or any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by You or any other party relating to the Services. Moreover, school leaders advantage.com reserves all intellectual property rights not expressly granted to You hereunder.</p>
          <p>In connection with the Services, school leaders advantage.com may, or may not permit You to, generate reports using the data ("Reports"). For avoidance of doubt, Reports are part of the Services and are owned by school leaders advantage.com. The Services may allow You to make data and/or Reports available to other groups or individuals You select through the Services ("Users"). You hereby grant such Users an irrevocable, perpetual, non-exclusive, worldwide, royalty-free right and license to use and exercise all rights in such data and Reports, as applicable.</p>
          <p>You may request, through Account Administration, or via a separate writing executed by both parties (a "Statement of Work"), that school leaders advantage.com professional services in connection with the Services (the "Professional Services"). Anything conceived or reduced to practice in connection with the Professional Services is owned by school leaders advantage.com. Any work product resulting from the Professional Services that is provided to You by school leaders advantage.com is part of the Services. You hereby grant school leaders advantage.com all rights, licenses, and permissions necessary and/or useful in connection with its performance of the Professional Services.</p>
          <p>Furthermore, school leaders advantage.com will provide all reasonable administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of the data and Reports. Those safeguards will include, but will not be limited to, measures for preventing access, use, modification or disclosure of the data by school leaders advantage.com except (a) to provide the Services and prevent or address service or technical problems, (b) as compelled by law, or (c) as You expressly permit in writing. Moreover, school leaders advantage.com shall, to the extent permitted by law, promptly notify You of any actual or reasonably suspected unauthorized disclosure of data by school leaders advantage.com of which school leaders advantage.com becomes aware.</p>

          <h2>Support</h2>
          <p>The school leaders advantage.com offers You support at info@inspiredschoolsolutions.com. Moreover, school leaders advantage.com will use commercially reasonable efforts to respond to Your support requests in a timely manner and to address the problems You are experiencing.</p>

          <h2>Fees and Payment</h2>
          <p>The fees applicable to the Services are set forth in Your Partnership Agreement or other service agreement (the "Fee Schedule").</p>
          <p>The school leaders advantage.com Services carry subscription fees ("Subscription Fees"). Subscription Fees will be due in advance on an annual or monthly basis, as agreed by You and school leaders advantage.com (the payment term applicable to You, the "Subscription Term"). At the beginning of each Subscription Term, school leaders advantage.com will invoice You (or, if You provided a credit card number through Account Administration, will bill that credit card) for the applicable Subscription Fees due for the following Subscription Term. Invoiced charges are due net thirty (30) days from the invoice date. If You owe Subscription Fees on a monthly basis, and if Your first month of subscription Services does not begin on the first day of a given calendar month, You will be charged a prorated amount of Subscription Fees for such first month, on the first day of such subscription Services.</p>
          <p>Fees for Professional Services are due net thirty (30) days from the invoice date, or as otherwise set forth in the applicable Statement of Work.</p>
          <p>Unless otherwise agreed by You and school leaders advantage.com in writing, all fees are non-refundable for any reason, including without limitation if this Agreement terminates prior to the end of a Subscription Term for which You have pre-paid Subscription Fees. All amounts due hereunder shall be in United States Dollars. Conversion of foreign currency to United States Dollars shall be made at the conversion rate existing in the United States (as reported in the Wall Street Journal) on the first working day of the calendar month during which the applicable payment is due.</p>
          <p>If the payment information You have provided is incorrect, incomplete, You are late paying any invoice, or school leaders advantage.com is otherwise unable to complete a transaction or collect timely payment due to Your error or omission, any payment due hereunder that is so delayed shall bear interest at the rate of one and one-half percent (1.5%) per month or the highest rate allowed by applicable law, whichever is less.</p>

          <h2>Term and Termination: Suspension</h2>
          <p>This Agreement shall remain in full force and effect while You use the Service in strict accordance with the terms, conditions, and limitations of this Agreement. Either party may terminate this Agreement for any reason or for no reason upon 10 (ten) days' written notice (via certified or registered mail, pre-paid and return receipt requested) to the other party. Moreover, school leaders advantage.com may suspend Your access to the Services and the performance of any Professional Services at any time and without notice, if school leaders advantage.com reasonably believes in its sole discretion that You have breached any of the terms of this Agreement.</p>
          <p>Upon termination of this Agreement for any reason other than Your breach, school leaders advantage.com may, in its discretion and upon Your request, use commercially reasonable efforts to provide You with limited assistance to transition the data stored on the Services to a third party or other repository designated by You. school leaders advantage.com reserves the right to charge fees for such transition assistance, on a time and materials basis, at school leaders advantage.com then-current rates. Also, school leaders advantage.com will not be obligated to provide any such transition assistance (a) at any time following the one (1) month anniversary of the effective date of termination, or (b) in excess of fifteen (15) person hours, in the aggregate.</p>
          <p>Upon termination of this Agreement, Your right to use and/or access the Services shall terminate, and school leaders advantage.com will cease performance of any Professional Services. The following provisions shall survive termination of this Agreement: Sections 3, 5 (as it relates to payment obligations incurred prior to termination), and 6-10 (inclusive). Notwithstanding the foregoing, after termination of this Agreement, You may continue to use Reports created by You, using only Your own data, that You have downloaded prior to the effective date of termination, solely in accordance with all restrictions herein.</p>

          <h2>Change to the Services</h2>
          <p>The Services may be updated from time to time. Moreover, school leaders advantage.com reserves the right to suspend or discontinue any part of the Services, introduce new features, impose limits on use of features, or restrict access to parts or all of the Services. Additionally, school leaders advantage.com will use commercially reasonable efforts to notify You of any material change to the Services that may adversely affect Your use of the Services.</p>

          <h2>Third Party Services</h2>
          <p>This Agreement shall remain in full force and effect while You use the Service in strict accordance with the terms, conditions, and limitations of this Agreement. Either party may terminate this Agreement for any reason or for no reason upon 10 (ten) days' written notice (via certified or registered mail, pre-paid and return receipt requested) to the other party. Additionally, school leaders advantage.com may suspend Your access to the Services and the performance of any Professional Services at any time and without notice, if school leaders advantage.com reasonably believes in its sole discretion that You have breached any of the terms of this Agreement.</p>
          <p>Upon termination of this Agreement for any reason other than Your breach, school leaders advantage.com may, in its discretion and upon Your request, use commercially reasonable efforts to provide You with limited assistance to transition the data stored on the Services to a third party or other repository designated by You. Furthermore, school leaders advantage.com reserves the right to charge fees for such transition assistance, on a time and materials basis, at school leaders advantage.com then-current rates. Moreover, school leaders advantage.com will not be obligated to provide any such transition assistance (a) at any time following the one (1) month anniversary of the effective date of termination, or (b) in excess of fifteen (15) person hours, in the aggregate.</p>
          <p>Upon termination of this Agreement, Your right to use and/or access the Services shall terminate, and school leaders advantage.com will cease performance of any Professional Services. The following provisions shall survive termination of this Agreement: Sections 3, 5 (as it relates to payment obligations incurred prior to termination), and 6-10 (inclusive). Notwithstanding the foregoing, after termination of this Agreement, You may continue to use Reports created by You, using only Your own data, that You have downloaded prior to the effective date of termination, solely in accordance with all restrictions herein.</p>

          <h2>Waiver of Ownership and Responsibility</h2>
          <p>The owners, partners, vendors, and subsidiaries of school leaders advantage.com claim no authorship, ownership, or proprietary relationship as it pertains to the non-original, linked content featured on this site. Moreover, we claim no responsibility for the accuracy, authenticity, topicality, or completeness of any linked content. Furthermore, we claim no responsibility, real or implied, for outcomes related to the application of any information or resources related to non-original, linked content. Subscribers utilize that information or resources at their own discretion and at their own risk. Additionally, we do not endorse any non-original information or resources for which links are provided. Where providers of non-original, linked information or resources charge fees for products or market their services, Subscribers purchase those at their own discretion and at their own risk. We at school leaders advantage.com will share no financial burden nor take responsibility for the consequences of those voluntary actions.</p>

          <h2>Miscellaneous</h2>
          <p>If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.</p>
          <p>This Agreement is not (by operation of law or otherwise) assignable, transferable or sublicensable by You except with school leaders advantage.com prior written consent. school leaders advantage.com may transfer and assign any of its rights and obligations under this Agreement freely and without consent.</p>
          <p>This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement. All revisions and modifications must be in a writing signed by both parties, except as otherwise provided herein. If there is any conflict between this Agreement and any Statement of Work or Partnership Agreement, this Agreement shall control (except to the extent the parties expressly state in the applicable agreement that it is intended to supersede any conflicting term herein).</p>
          <p>No agency, partnership, joint venture, or employment is created as a result of this Agreement and You do not have any authority of any kind to bind school leaders advantage.com in any respect whatsoever. There are no third-party beneficiaries to this Agreement.</p>
          <p>All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; and upon receipt, if sent by certified or registered mail (return receipt requested), postage prepaid</p>
          <p>This Agreement will be governed by the laws of the State of New York, U.S.A., without regard to its conflict of laws provisions. Any dispute arising from or relating to the subject matter of this Agreement shall be finally settled by arbitration in Kings County, Brooklyn, using the English language in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the Streamlined Arbitration Rules and Procedures of JAMS. Judgment upon the award so rendered may be entered in a court having jurisdiction, or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator. For all purposes of this Agreement, the parties consent to exclusive jurisdiction and venue in the state and federal courts in Kings County, Brooklyn.</p>
          <p>You hereby grant school leaders advantage.com the right to use Your name(s), mark(s), and logo(s) to identify You as a school leaders advantage.com customer, and otherwise in its publicity and marketing materials, unless You notify school leaders advantage.com in writing otherwise.</p>
          <p>THE SERVICES, PROFESSIONAL SERVICES, REPORTS, AND ANYTHING PROVIDED IN CONNECTION WITH THIS AGREEMENT ARE PROVIDED "AS-IS," WITHOUT ANY WARRANTIES OF ANY KIND. SCHOOL LEADERS AVANTAGE.COM HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE SUBJECT MATTER HEREUNDER, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</p>
        </div>
      </main>

    </Fragment>
  );
}