import React, { useState } from "react";

import LoginForm from "./signIn";
import SignUpForm from "./signUp";
import { Tabs, Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import "./index.css";

const LoginSignupPage = ({ isSignup }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const login = async (username, password) => {
    try {
      let res = await auth.login(username, password);
      navigate("/chat", { replace: true });
      window.location.reload();
    } catch (error) {
      throw error;
    }
  };

  const signUp = (
    first_name,
    last_name,
    password,
    email,
    role
  ) => {
    return auth.signup(
      first_name,
      last_name,
      password,
      email,
      role
    );
  };

  return (
    <React.Fragment>
      <div className="h-screen w-screen flex overflow-clip">
        <div
          className="md:basis-1/2 relative invisible md:visible"
          style={{
            background: "rgb(0,105,203)",
            background:
              "linear-gradient(0deg, rgba(0,105,203,1) 12%, rgba(0,105,203,1) 32%, rgba(0,105,203,1) 41%, rgba(60,151,238,1) 97%, rgba(60,151,238,1) 100%, rgba(60,151,238,1) 100%)",
          }}
        >
          <img
            src="/boxes.svg"
            className="absolute top-0 w-[15vw] opacity-60 -left-10 opacity-50"
          />

          <div className="absolute  left-0 right-0 -bottom-20 flex flex-col justify-center items-start max-w-[44rem] mx-auto  md:px-12 ">
            <h1 className="text-white text-6xl text-left">
              School Leader's Advantage
            </h1>

            <div className="text-white text-lg mt-4 opacity-80">
              <p>
                School Leader's Advantage is a platform that provides a
                personalized experience for school leaders to access resources,
                chat with SLA, and access standards.
              </p>
            </div>
            <div className="flex mt-6 w-full overflow-clip">
              <img src="/dots.svg" className="w-2/5 opacity-60 " />
              <img src="/dots.svg" className="w-2/5 opacity-60 " />
              <img src="/dots.svg" className="w-2/5 opacity-60 " />
            </div>
          </div>
        </div>

        <div className="md:basis-1/2 flex justify-center items-center gap-8 pb-32">
          <div
            style={{
              // maxWidth: "600px",
              margin: "0px auto",
            }}
            className="md:max-w-[44rem] px-6 w-full md:px-12"
          >
            <img
              src="/logo.png"
              alt="School Leader's Advantage | Logo"
              className="h-16 my-16"
            />
            <div className="text-4xl text-endeavour-600 my-8">
              <h1 className="title">
                {isSignup ? "Create an Account" : "Welcome Back"}
              </h1>
            </div>

            {isSignup ? (
              <SignUpForm onSubmit={signUp} />
            ) : (
              <LoginForm onSubmit={login} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginSignupPage;
