import React, { Fragment } from "react";
import "./Legal.css";

export default function DMCA() {
  return (
    <Fragment>
      {/* <Navbar /> */}

      <main className="wrapper legal privacy-policy">
        <div className="header">
          <h1>DMCA Safe Harbor Statement</h1>
        </div>

        <div className="item">
        <h2>DMCA Safe Harbor Statement</h2>

          <p>
            Schoolleadersadvantage.com respects the intellectual property rights
            of others and expects its users to do the same. In accordance with
            the Digital Millennium Copyright Act (DMCA), we have adopted the
            following policy toward copyright infringement on our website.
          </p>

          <h2>Notification of Claimed Copyright Infringement</h2>
          <p>
            If you believe that any content on our website infringes upon your
            copyright, you may submit a notification pursuant to the DMCA by
            providing our designated Copyright Agent with the following
            information in writing:
          </p>
          <ul>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works on the website are
              covered by a single notification, a representative list of such
              works.
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material.
            </li>
            <li>
              Information reasonably sufficient to permit us to contact you,
              such as an address, telephone number, and, if available, an email
              address at which you may be contacted.
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law.
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that you are authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
          </ul>

          <h2>Copyright Agent Contact Information</h2>
          <p>
            Designated Copyright Agent:{" "}
            <a href="mailto:info@inspiredschoolsolutions.com">
              info@inspiredschoolsolutions.com
            </a>
          </p>
          <p>315 Flatbush Avenue, Ste. 523, Brooklyn, NY 11217-2813</p>
          <a href="mailto:info@inspiredschoolsolutions.com">
            info@inspiredschoolsolutions.com
          </a>
          <p>Thank you.</p>
        </div>
      </main>
    </Fragment>
  );
}
