import React, { useState } from "react";

import { OpenIcon } from "../../components/Icons.jsx";
import { useQuery } from "react-query";
import { BotService, StandardService } from "../../services/dataService.js";
import StateDropdown from "./stateDropdown.js";

import "./Standards.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Input, Modal, Spin, Typography } from "antd";

import createPersistedState from "use-persisted-state";

const statePersistedState = createPersistedState("state");

const SubTitleView = ({ standard, status, setStatus }) => {
  const { id, title, description } = standard;
  const subtitles = useQuery("subtitles", () =>
    StandardService.getSubtitles(id)
  );
  const navigate = useNavigate();

  const chatHandover = async (content) => {
    // Create a new session
    let res = await BotService.createChatSession({
      subtitleId: id,
      title: title,
    });

    navigate("/question", {
      state: {
        sessionId: res.id,
        firstMessage: `Tell me more about educational standard: How to ${content}`,
      },
    });
  };
  return (
    <>
      <div className=" my-2">
        <div className="text-base font-medium text-gray-400">
          Select any subtitle to start chatting
        </div>
        {subtitles.data?.results?.map((subtitle) => (
          <div
            key={subtitle.id}
            className="text-base  text-gray-600 transition hover:text-blue-500 cursor-pointer"
            onClick={() => chatHandover(subtitle.content)}
          >
            <div className="border-b py-3">{subtitle.content}</div>
          </div>
        ))}
        {subtitles.isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "400px",
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};

export default function Standards({ status, setStatus, title, category }) {
  const [state, setState] = statePersistedState({
    selectedState: "",
  });

  const [selectedStandard, setSelectedStandard] = useState(null);
  const navigate = useNavigate();

  const standards = useQuery(
    ["standards", category, state.selectedState, category],
    () =>
      StandardService.getAll({
        state: category == "state" ? state.selectedState : null,
      }),
    {
      enabled:
        category == "state" ? (state.selectedState ? true : false) : true,
    }
  );

  const handleSelect = (value) => {
    setState({ ...state, selectedState: value });
  };

  // debugger;
  return (
    <div className="wrapper standards">
      <Modal
        visible={selectedStandard}
        onCancel={() => setSelectedStandard(null)}
        width={800}
        footer={null}
        // style={{
        //   width: "800px",
        // }}
      >
        <Typography.Title level={4} className="text-gray-600">
          {selectedStandard?.title}
        </Typography.Title>
        {/* <Typography.Text className="text-gray-500">
          {selectedStandard?.description}
        </Typography.Text> */}
        <SubTitleView
          key={selectedStandard?.id}
          standard={selectedStandard}
          status={status}
          setStatus={setStatus}
        />
      </Modal>
      <div className="header">
        <div className="text-endeavour-600 text-2xl font-medium">{title}</div>
      </div>

      {category == "state" && (
        <>
          <StateDropdown value={state.selectedState} onChange={handleSelect} />

          <div style={{ marginTop: "0.5rem" }}>
            <Typography.Paragraph>
              Does your State use the Professional Standards for Educational
              Leaders (PSEL) standards or any other Educational Standards not on
              the drop-down list? If so, Please type the standards statement you
              want to find best practices for into the Search Bar and press
              "Enter."
            </Typography.Paragraph>
            <Input.Search
              onSearch={(e) => navigate(`/search?searchText=${e}`)}
            />
          </div>
        </>
      )}

      <div className="standards-list">
        {!standards.isLoading
          ? standards.data?.results.map((standard) => (
              <StandardItem
                key={standard.id}
                status={status}
                // setStatus={setStatus}
                standard={standard}
                isState={category == "state"}
              />
              // <StandardItemV2
              //   key={standard.id}
              //   status={status}
              //   standard={standard}
              //   onSelect={(standard) => setSelectedStandard(standard)}
              // />
            ))
          : null}
      </div>
      {standards.isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "400px",
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}

function StandardItem({ status, standard, isState }) {
  const { id, title, description } = standard;

  return (
    // <div >
    <Link to={isState ? `/state-standards/${id}` : `/standards/${id}`}>
      <Card
        hoverable
        style={{
          height: "100%",
        }}
      >
        <div className="card">
          <div className="text-lg text-endeavour-600 mb-2">{title}</div>

          <p className="text-gray-600">{description}</p>

          {/* <div className="actions">
            <Button>Read More</Button>
        </div> */}
        </div>
      </Card>
    </Link>
  );
}

// function StandardItemV2({ status, standard, onSelect }) {
//   const { id, title, description } = standard;

//   return (
//     // <div >
//     <Card
//       hoverable
//       style={{
//         height: "100%",
//       }}
//       onClick={() => onSelect(standard)}
//     >
//       <div className="card">
//         <Typography.Title level={5}>{title}</Typography.Title>

//         <Typography.Text>{description}</Typography.Text>

//         {/* <div className="actions">
//             <Button>Read More</Button>
//         </div> */}
//       </div>
//     </Card>
//   );
// }
