import React, { useEffect, useState } from "react";
import ChatBot from "../../components/chatbotV2";
import { useMutation, useQuery } from "react-query";
import { BotService, ChatbotSession } from "../../services/dataService";
import "./index.css";
import { message, Tabs } from "antd";
import { useLocation, useParams } from "react-router-dom";
import ResourcesView from "../../components/chatbot/resources";

const ChatDetail = (props) => {
  // Get passed data
  const params = useParams();
  const location = useLocation();
  const [state, setState] = React.useState({
    sessionId: params.sessionId || "",
    session: null,
    loading: true,
  });

  const [resources, setResources] = useState([]);
  
  const resourcesQuery = useQuery(
    ["resources", params.sessionId],
    () => ChatbotSession.get_resources(params.sessionId),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        setResources(data.results || []);
      },
    }
  );

  const [tab, setTab] = useState("chat");

  useEffect(() => {
    if (params.sessionId) {
      ChatbotSession.get(params.sessionId).then((response) => {
        setState((prev) => ({
          ...prev,
          session: response.results,
          shouldSendMessage: response.num_messages === 0,
          loading: false,
        }));
      });
    }
  }, [state.sessionId]);

  const onClear = async () => {
    await BotService.clearSession(state.sessionId);
    message.success("Session cleared");
  };

  const isMd = window.innerWidth > 768;

  return (
    <div className="chat-page h-full w-full">
      <div className="header  h-36 h-auto">
        <div className="md:flex md:justify-between border-b border-gray-300 md:h-10 h-auto">
          <div className="text-xl text-endeavour-600">{state.session?.title?.replace('"', '').replace('"', '') || "Untitled Session"}</div>
          {/* <div className="rounded-lg  h-10">
            <button
              className={`h-10 text-sm  px-4 transition ml-auto  ${
                tab === "chat"
                  ? "text-endeavour-600 border-b-2 border-endeavour-600"
                  : "text-gray-500"
              }`}
              onClick={() => setTab("chat")}
            >
              <i class="fa-regular fa-message mr-2"></i>
              Chat
            </button>
            <button
              className={` h-10 text-sm px-4 transition ml-auto  ${
                tab === "resources"
                  ? "text-endeavour-600 border-b-2 border-endeavour-600 "
                  : "text-gray-500"
              }`}
              onClick={() => setTab("resources")}
            >
              <i class="fa-regular fa-file-lines mr-2"></i>
              Resources
            </button>
          </div> */}
        </div>
      </div>

      <div
        className=" overflow-auto md:py-0 py-4"
        style={{
          height: isMd ? "calc(100% - 32px)": "calc(100% - 120px)",
          display: tab === "chat" ? "block" : "none",
        }}
      >
        {state.loading && (
          <div className="h-96 flex justify-center items-center">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </div>
        )}
        {!state.loading && (
          <ChatBot
            onSessionChange={(sessionId) => {
              setState((prev) => ({
                ...prev,
                sessionId,
              }));
            }}
            firstMessage={
              state.shouldSendMessage &&
              location.state &&
              location.state.firstMessage
                ? location.state.firstMessage
                : null
            }
            sessionId={params.sessionId}
            onClear={onClear}
            addResources={(resources) => setResources(
              (prev) => [...prev, ...resources]
            )}
            v2={true}
          />
        )}
      </div>
      <div
        className="h-full overflow-auto"
        style={{
          display: tab === "resources" ? "block" : "none",
          height: "calc(100% - 48px)",
        }}
      >
        <>
          <ResourcesView resources={resources} />
        </>
      </div>
    </div>
  );
};

export default ChatDetail;
