import React from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "../../components/Functions.jsx";
import { ProjectIcon, DeleteIcon } from "../../components/Icons.jsx";
import "./Projects.css";
import { Button, Card, Col, Row, Tag, Typography, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import { ProjectService } from "../../services/dataService.js";

export default function ProjectItem({ project }) {
  const queryClient = useQueryClient();
  // Check if all outcomes, milestones, evaluations are completed
  const items = [
    ...project.outcomes,
    ...project.milestones,
    ...project.evaluations,
  ];
  const hasPendingItems = items.filter((item) => !item.completed).length > 0;

  const deleteProject = useMutation(
    () => ProjectService.delete(project._id || project.id),
    {
      onSuccess: () => {
        message.success("Project deleted");
        queryClient.invalidateQueries("projects");
      },
    }
  );

  return (
    <Card>
      {/* <div className="icon">
        <ProjectIcon />
      </div> */}

      <h4>
        {project.name}{" "}
        <Tag color={hasPendingItems ? "orange" : "green"} style={{float: "right"}}>
          {hasPendingItems ? "Active" : "Completed"}
        </Tag>
      </h4>
      <span>Deadline: {dateFormat(new Date(project.endDate))}</span>

      <Typography.Paragraph>{project.summary}</Typography.Paragraph>

      <Row gutter={12} className="actions">
        <Col>
          <Link to={`/projects/${project._id || project.id}`}>
            
            <Button type="link" size="small" className="text-action-btn">
            <i class="fa-solid fa-arrow-up-right-from-square button-icon"></i>
              Open Project</Button>
          </Link>
        </Col>
        <Col>
          <Button
            type="link"
            size="small"
            className="text-action-btn"
            danger
            onClick={deleteProject.mutate}
            loading={deleteProject.isLoading}
          >
            <i class="fa-solid fa-trash trash-icon"></i>
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
