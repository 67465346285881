import React, { useState, useReducer, useEffect, Fragment } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
// import clsx from "clsx";
import { WarningIcon, AddIcon } from "../../components/Icons.jsx";
import { dateFormat } from "../../components/Functions.jsx";
import Checkbox from "../../components/Checkbox.jsx";
import "./Projects.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ProjectService } from "../../services/dataService.js";
import { Button, DatePicker, Form, Input, Row, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { ProjectOutcome } from "./projectForm/outcome.js";
import { ProjectEvaluation } from "./projectForm/evaluations.js";
import { ProjectMilestones } from "./projectForm/milestones.js";
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function ProjectEditCreate() {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [data, setData] = useReducer(
    (prev, next) => {
      const newEvent = { ...prev, ...next };

      // Add date format to startDate, endDate
      newEvent.startDate = new Date(newEvent.startDate).toISOString();
      newEvent.endDate = new Date(newEvent.endDate).toISOString();

      return newEvent;
    },
    {
      name: "",
      summary: "",
      startDate: new Date(),
      endDate: new Date(),
      outcomes: [],
      milestones: [],
      evaluations: [],
    }
  );

  const [state, setState] = useState({
    data: {},
    loading: false,
    error: "",
    createOutcome: false,
    createMilestone: false,
    createEvaluation: false,
  });

  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const project = useQuery(
    ["project", projectId],
    () =>
      ProjectService.get(projectId).then((res) => {
        // Convert all dates
        res.startDate = dayjs(res.startDate);
        res.endDate =dayjs(res.endDate);

        // Convert timeline in outcomes
        res.outcomes = res.outcomes.map((outcome) => {
          outcome.timeline =  dayjs(outcome.timeline)
          return outcome;
        });

        // Convert deadline in milestones
        res.milestones = res.milestones.map((milestone) => {
          milestone.deadline =dayjs(milestone.deadline);
          return milestone;
        });

        return res;
      }),
    {
      enabled: projectId ? true : false,
    }
  );

  console.log("project", project)

  // useEffect(() => {
  //   if (storedProject) setData(storedProject);
  //   if (location.state)
  //     setData({ name: location.state.name, summary: location.state.summary });
  // }, [storedProject]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, error: "" }); // Hide error when updating data
    setData({ [name]: value });
  };

  const createProject = useMutation((values) => ProjectService.create(values), {
    onSuccess: (data) => {
      message.success("Project created successfully");
      navigate("/projects");
      queryClient.invalidateQueries("project");
    },
    onError: (error) => {
      console.log(error);
      message.error("Error creating project");
    },
  });

  const updateProject = useMutation(
    (values) => ProjectService.update(projectId, values),
    {
      onSuccess: (data) => {
        message.success("Project updated successfully");
        navigate("/projects");
        queryClient.invalidateQueries("project");
      },
      onError: (error) => {
        console.log(error);
        message.error("Error updating project");
      },
    }
  );

  const handleSubmit = (values) => {
    // Convert all dates

    values.startDate = values.startDate.toISOString();
    values.endDate = values.endDate.toISOString();
    // Covert timeline in outcomes
    // values.outcomes = values.outcomes.map((outcome) => {
    //   outcome.timeline = outcome.timeline.toISOString();
    //   return outcome;
    // });

    // Convert deadline in milestones
    // values.milestones = values.milestones.map((milestone) => {
    //   milestone.deadline = milestone.deadline.toISOString();
    //   return milestone;
    // });

    if (projectId) {
      updateProject.mutate(values);
      return;
    } else {
      createProject.mutate(values);
    }
  };

  if (project.isLoading) return;

  return (
    <div className="project-view">
      <div className="header">
        <h1>Project</h1>
        {/* <Link
          to="/projects"
          className="button secondary"
          style={{
            marginLeft: "auto",
          }}
        > */}
          <Button type="link" 
          onClick={
            () => navigate(-1)
          }
          >Back</Button>
        {/* </Link> */}
      </div>

      <div className="item container">
        <p className="subtitle">
          Implementation, Tracking and Evaluation Module (Project).
        </p>

        {!project.isLoading ? (
          <Form
            layout="vertical"
            form={form}
            name="register"
            onFinish={handleSubmit}
            initialValues={project.data ? project.data : {
              name: location.state ? location.state?.title : "",
              summary:  location.state ? location.state?.summary : "",
            }}
            style={{ maxWidth: 800, margin: "0px auto", marginTop: "1.5rem" }}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Project Name"
              rules={[{ required: true, message: "Please add project name!" }]}
            >
              <Input placeholder="e.g. Leaders engage the community..." />
            </Form.Item>

            <Form.Item
              name="summary"
              label="Summary"
              rules={[{ required: true, message: "Please add summary!" }]}
            >
              <Input.TextArea placeholder="e.g. Engagement is one of the most..." />
            </Form.Item>

            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[{ required: true, message: "Please add Start Date!" }]}
            >
              <DatePicker placeholder="Start Date" />
            </Form.Item>

            <Form.Item
              name="endDate"
              label="Complete Date"
              rules={[{ required: true, message: "Please add End Date!" }]}
            >
              <DatePicker placeholder="End Date" />
            </Form.Item>

            <Form.List name="outcomes">
              {(fields, { add, remove }) => (
                <>
                  <ProjectOutcome
                    fields={fields}
                    add={add}
                    remove={remove}
                    form={form}
                  />
                </>
              )}
            </Form.List>
            <Form.List name="milestones">
              {(fields, { add, remove }) => (
                <>
                  <ProjectMilestones
                    fields={fields}
                    add={add}
                    remove={remove}
                    form={form}
                  />
                </>
              )}
            </Form.List>
            <Form.List name="evaluations">
              {(fields, { add, remove }) => (
                <>
                  <ProjectEvaluation
                    fields={fields}
                    add={add}
                    remove={remove}
                    form={form}
                  />
                </>
              )}
            </Form.List>

            <div
              className="actions"
              style={{
                marginTop: "2rem",
              }}
            >
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  // type="button"
                  // onClick={handleSubmit}
                  // disabled={state.error}
                  loading={createProject.isLoading || updateProject.isLoading}
                  // className="button primary"
                >
                  {createProject.isLoading || updateProject.isLoading
                    ? "Saving Project"
                    : "Save Project"}
                </Button>
                <Button
                  // type="button"
                  onClick={() => navigate("/project")}
                  // className="button secondary"
                  style={{ marginLeft: "1rem" }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : null}
      </div>
    </div>
  );
}
