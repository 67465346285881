// import React, { Component } from "react";
// import { annotations } from "./Sample Annotation List/annotationList";

let sampleAnnotation = {
  id: 0,
  comment: {
    color: "#51E5FF",
    cluster: "DESCRIPTION",
    is_bullet: false,
    cluster_color: "#51E5FF",
    resolved_cluster: "DESCRIPTION",
  },
  content: {
    html: "<p>SIGMA Electric Manufacturing Corporation Pvt.Ltd. </p>",
    text: "SIGMA Electric Manufacturing Corporation Pvt.Ltd. ",
  },
  position: {
    rects: [
      {
        x1: 227.86000061035156,
        x2: 515.8153076171875,
        y1: 69.58877563476562,
        y2: 79.38653564453125,
        width: 595.4400024414062,
        height: 841.9199829101562,
      },
    ],
    pageNumber: 1,
    boundingRect: {
      x1: 101.69999694824219,
      x2: 515.8153076171875,
      y1: 66.99998474121094,
      y2: 83.69998168945312,
      width: 595.4400024414062,
      height: 841.9199829101562,
    },
  },
};

export const transformAnnotation = (anno, fileId = "1") => {
  var targetAnno = {
    "@context": [
      "https://www.w3.org/ns/anno.jsonld",
      "https://comments.acrobat.com/ns/anno.jsonld",
    ],
    type: "Annotation",
    id: String(anno.id),
    bodyValue: "",
    motivation: "commenting",
    target: {
      source: fileId,
      selector: {
        node: {
          index: anno.id,
        },
        opacity: 0.6,
        subtype: "freetext",
        boundingBox: [],
        strokeColor: "#008b02",
        strokeWidth: 3,
        type: "AdobeAnnoSelector",
      },
    },
    creator: {
      type: "Person",
      name: "TMS",
    },
    created: "2020-04-03T14:56:54Z",
    modified: "2020-04-03T15:00:14Z",
  };
  var rawRects = anno.position?.rects || [];
  if (rawRects.length > 0) {
    var quadPoints = [];
    rawRects.map((item) => {
      var height = item["height"];
      var width = item["width"];
      quadPoints.push(
        ...[
          item.x1,
          item.y1,
          item.x2,
           item.y1,
          item.x2,
          item.y2,
          item.x1,
          item.y2,
        ]
      );
    });
    targetAnno["target"]["selector"]["quadPoints"] = quadPoints;
    targetAnno["target"]["selector"]["subtype"] = "highlight";
  } else {
    var rawBounding = anno.position.boundingRect;

    var height = rawBounding["height"];
    var width = rawBounding["width"];
    var inklist = [];
    inklist = [
      [
        rawBounding.x1,
        rawBounding.y2,
        rawBounding.x2,
        rawBounding.y2,
        rawBounding.x2,
        rawBounding.y1,
        rawBounding.x1,
        rawBounding.y1,
        rawBounding.x1,
        rawBounding.y2,
      ],
      // [rawBounding.x2, height - rawBounding.y2],
      // [rawBounding.x1, height - rawBounding.y1],
      // [rawBounding.x2, height - rawBounding.y1],
    ];
    targetAnno["target"]["selector"]["inkList"] = inklist;
    targetAnno["target"]["selector"]["subtype"] = "shape";
  }
  var rawBoundingRect = anno.position.boundingRect;
  var height = rawBoundingRect["height"];
  var width = rawBoundingRect["width"];
  targetAnno["target"]["selector"]["boundingBox"] = [
    rawBoundingRect.x1 -5,
    // height - rawBoundingRect.y2-5 ,
   rawBoundingRect.y2-20 ,

    rawBoundingRect.x2+5,
    // height - rawBoundingRect.y1+5,
    rawBoundingRect.y1+20,

  ];
  targetAnno["target"]["selector"]["node"]["index"] =
    anno.position.pageNumber ;
  return targetAnno;
};
