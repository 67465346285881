import React, { useEffect } from "react";
import ChatBot from "../../components/chatbot";
import { useMutation, useQuery } from "react-query";
import { BotService } from "../../services/dataService";
import "./index.css";
import { message } from "antd";
import { useLocation } from "react-router-dom";

const ChatBotPage = (props) => {
  const [state, setState] = React.useState({
    sessionId: "",
    loading: true,
  });
  // Get passed data
  const location = useLocation();

  useEffect(() => {
    BotService.getChatbotSession().then((response) => {
      setState((prev) => ({
        ...prev,
        sessionId: response.id,
        loading: false,
      }));
    });
  }, []);

  const onClear = async () => {
    await BotService.clearSession(state.sessionId);
    message.success("Session cleared");
  };

  return (
    <div className="chat-page h-full">
      {/* <div className="text-xl">
        <h1>Untitle</h1>
      </div> */}
      <div className="h-full">
        {state.loading && <div>Loading...</div>}
        {!state.loading && (
          <ChatBot
            onSessionChange={(sessionId) => {
              setState((prev) => ({
                ...prev,
                sessionId,
              }));
            }}
            firstMessage={
              location.state && location.state.firstMessage
                ? location.state.firstMessage
                : null
            }
            sessionId={location.state?.sessionId || state.sessionId}
            onClear={onClear}
          />
        )}
      </div>
    </div>
  );
};

export default ChatBotPage;
