import React from "react";
import {
  BotService,
  ChatbotSession,
  SearchService,
} from "../../services/dataService";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../authContext";
import moment from "moment";
import {
  Badge,
  Button,
  Card,
  Empty,
  Modal,
  Space,
  Tag,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";

const ResourceItem = ({ item, onSelect }) => {
  const [showAbstract, setShowAbstract] = React.useState(false);
  const navigate = useNavigate();
  // let downloadFile = useMutation((id) => SearchService.getDocument(item?.id));
  let openPDF = (id) => {
    // downloadFile.mutateAsync(id).then((res) => {
    //   // Convert res to blob
    //   var blob = new Blob([res], { type: "application/pdf" });
    //   var url = URL.createObjectURL(blob);
    //   // Open in new tab
    //   window.open(url, "_blank");
    // });

    window.open(item.pdf_url ? item.pdf_url : item.url, "_blank");
  };

  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.openai_summary?.title || item.title,
        summary: item.openai_summary?.abstract || item.abstract,
      },
    });
  };
  return (
    <div onClick={() => onSelect && onSelect(item)}>
      <Card key={item.id}>
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div className="w-full ">
              <p
                className="w-full text-base font-medium  truncate whitespace-nowrap"
                style={{
                  color: "var(--primary-400)",
                }}
              >
                {item.openai_summary?.title || item.name || item.title}
              </p>

              {/* {item.is_processed ? (
                <div className="mt-2 flex justify-start items-center gap-1">
                  <i className="fa-regular fa-user"></i>
                  <>
                    {item.openai_summary?.authors
                      ?.split(",")
                      .slice(0, 3)
                      .map((item) => (
                        <Tag
                          style={{
                            border: "none",
                            background: "var(--secondary-100)",
                            color: "var(--secondary)",
                          }}
                        >
                          {item}
                        </Tag>
                      ))}
                  </>
                </div>
              ) : null} */}
            </div>
          </div>
          {/* {item.is_processed ? (
            <div style={{ padding: "8px 0px" }}>
              {item.openai_summary?.standard_summaries?.length > 0 &&
                item.openai_summary?.standard_summaries
                  .slice(0, 3)
                  .map((item, idx) => (
                    <Tag className="primary-chip" key={idx}>
                      {item.standard_title}
                    </Tag>
                  ))}
            </div>
          ) : null} */}
        </div>

        <Modal
          title="Abstract"
          open={showAbstract}
          onCancel={() => setShowAbstract(false)}
          width={600}
          footer={null}
        >
          <Typography.Paragraph>{item.abstract}</Typography.Paragraph>
        </Modal>

        <Space direction="horizontal" wrap>
          <Button
            onClick={() => setShowAbstract(true)}
            size="small"
            type="text"
            className="text-action-btn"
          >
            <i className="fa-regular fa-file-lines button-icon"></i>
            View Abstract
          </Button>
          <Button
            onClick={() => openPDF(item.id)}
            size="small"
            type="text"
            className="text-action-btn"
          >
            <i className="fa-solid fa-link button-icon"></i>
            Go to Link
          </Button>
          <Button
            // onClick={() => window.open(item.pdf_link, "_blank")}
            onClick={() => openItem(item)}
            size="small"
            type="text"
            className="text-action-btn"
          >
            <i className="fa-regular fa-pen-to-square  button-icon"></i>
            Open in Item
          </Button>
          {/* <Button
            onClick={() => setSelected(item)}
            size="small"
            type="text"
            className="text-action-btn"
          >
            <i className="fa-regular fa-rectangle-list button-icon"></i>
            View Summary
          </Button> */}
        </Space>
      </Card>
    </div>
  );
};

const ResourcesView = ({ resources }) => {
  // let documentIds = resources?.map(
  //   (resource) => resource.document_id || resource.id
  // );
  // let botResources = useQuery(documentIds, () =>
  //   BotService.getResources({
  //     document_ids: documentIds,
  //   })
  // );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      {resources?.map((resource) => (
        <div key={resource.id} className="">
          <ResourceItem key={resource.id} item={resource} />
        </div>
      ))}
      {/* {sessions.data?.results?.map((session) => (
        <div key={session.id} className={ activeId == session.id ? "session-item active" : "session-item"} >
        <div> Started At:</div>
          <span>{moment(session.created_at).format("MMMM Do YYYY, h:mm:ss a")}</span>
        </div>
      ))} */}
    </div>
  );
};

export default ResourcesView;
