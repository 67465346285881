import React from "react";
import { CaretDownIcon } from "../../components/Icons.jsx";
import { Select } from "antd";

export default function StateDropdown({ value, onChange }) {
  const handleChange = (value) => {
    onChange(value);
  };

  const states = [
    { _id: "alabama", name: "Alabama" },
    { _id: "alaska", name: "Alaska" },
    { _id: "arkansas", name: "Arkansas" },
    { _id: "california", name: "California" },
    { _id: "connecticut", name: "Connecticut" },
    { _id: "colorado", name: "Colorado" },
    { _id: "florida", name: "Florida" },
    { _id: "georgia", name: "Georgia" },
    { _id: "hawaii", name: "Hawaii" },
    { _id: "idaho", name: "Idaho" },
    { _id: "illinois", name: "Illinois" },
    { _id: "iowa", name: "Iowa" },
    { _id: "kansas", name: "Kansas" },
    { _id: "kentucky", name: "Kentucky" },
    { _id: "louisiana", name: "Louisiana" },
    { _id: "maine", name: "Maine" },
    { _id: "maryland", name: "Maryland" },
    { _id: "michigan", name: "Michigan" },
    { _id: "missouri", name: "Missouri" },
    { _id: "montana", name: "Montana" },
    { _id: "mississippi", name: "Mississippi" },
    { _id: "massachusetts", name: "Massachusetts" },
    { _id: "nebraska", name: "Nebraska" },
    { _id: "nevada", name: "Nevada" },
    { _id: "north_carolina", name: "North Carolina" },
    { _id: "new_hampshire", name: "New Hampshire" },
    { _id: "new_mexico", name: "New Mexico" },
    { _id: "oregon", name: "Oregon" },
    { _id: "oklahoma", name: "Oklahoma" },
    { _id: "ohio", name: "Ohio" },
    { _id: "pennsylvania", name: "Pennsylvania" },
    { _id: "rhode_island", name: "Rhode Island" },
    { _id: "south_dakota", name: "South Dakota" },
    { _id: "tennessee", name: "Tennessee" },
    { _id: "texas", name: "Texas" },
    { _id: "utah", name: "Utah" },
    { _id: "virginia", name: "Virginia" },
    { _id: "west_virginia", name: "West Virginia" },
    { _id: "wisconsin", name: "Wisconsin" },
    { _id: "dodea", name: "DoDEA Standards" },
    { _id: "search", name: "Other ISLLC Standards" },
  ];

  return (
    <div>
      {/* <select value={value} onChange={handleChange}>
        <option value="">Select</option>
        {states.map((state) => <option value={state._id} key={state._id}>{state.name}</option>)}
      </select>

      <CaretDownIcon /> */}

      <Select
        style={{ width: "100%" }}
        value={value}
        onChange={handleChange}
        allowClear
        showSearch
        placeholder="Select a state"
      >
        {states.map((state) => (
          <Select.Option value={state._id} key={state._id}>
            {state.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
