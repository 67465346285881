import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
  FavouriteService,
  SearchService,
  StandardService,
} from "../../services/dataService";
import { Button, Col, Input, Modal,  Row, Tabs } from "antd";
import PDFDocumentView from "../../components/pdfViewer/index.js";
import SimpleSearchView from "./simple";
import AzureSearchView from "./azure";
import VectorSearchView from "./vector";
import { ArticleView, BlogView, ResourceView } from "../subtitleViewV2/index.jsx";

export default function SearchView({ status, setStatus }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { search } = useLocation();
  let searchText = new URLSearchParams(search).get("searchText");

  const [state, setState] = useState({
    searchText: searchText,
  });
  const subtitles = useQuery("subtitles", () =>
    StandardService.getSubtitles(id)
  );

  const favourite = useQuery(["favourites"], () =>
    FavouriteService.getAllMap()
  );

  const onChangeFavourite = () => {
    queryClient.invalidateQueries("favourites");
  };
  //   const searchService = useQuery(["search", state.searchText], () =>
  //     SearchService.simpleSearch({
  //       search: state.searchText,
  //     })
  //   );

  const handlePrev = () =>
    setStatus({ ...status, view: "standards", standardId: undefined });

  const tabItems = [
    {
      key: "simple",
      label: `Simple`,
      children: (
        <SimpleSearchView
          searchText={state.searchText}
          enabled={state.activeTab == "simple"}
        />
      ),
    },
    {
      key: "azure",
      label: `Azure`,
      children: (
        <AzureSearchView
          searchText={state.searchText}
          enabled={state.activeTab == "azure"}
        />
      ),
    },
    {
      key: "openai",
      label: `OpenAI`,
      children: (
        <VectorSearchView
          searchText={state.searchText}
          enabled={state.activeTab == "openai"}
        />
      ),
    },
  ];

  const ragSearch = useQuery(
    ["ragsearch", state.searchText],
    () =>
      SearchService.ragSearchV2({
        search: state.searchText,
        // page: state.page,
      }),
    {
      enabled: state.searchText ? true : false,
      staleTime: Infinity,
    }
  );

  return (
    <div className="wrapper subtitles">
      <Modal
        width={1000}
        title="PDF Viewer"
        open={state.selectItem}
        onCancel={() => setState((prev) => ({ ...prev, selectItem: false }))}
        footer={null}
        destroyOnClose={true}
      >
        <div
          style={{
            height: "80vh",
          }}
        >
          <PDFDocumentView
            id={state.selectItem?._id}
            url={state.selectItem?._source?.url}
            title={state.selectItem?._source?.title}
          />
        </div>
      </Modal>
      <div className="header">
        <h1>Search</h1>
        <Button onClick={() => navigate(-1)} type="link">
          Back
        </Button>
      </div>

      <Row justify="center">
        <Col flex={"auto"}>
          <Input.Search
            // size="large"
            defaultValue={state.searchText}
            allowClear
            onSearch={(searchText) =>
              setState((prev) => ({ ...prev, searchText: searchText }))
            }
          ></Input.Search>
        </Col>
        {/* <Col>
          <Popover
            content={
              <div>
                <p>Filters</p>
              </div>
            }
            title="Title"
          >
            <Button>Filters</Button>
          </Popover>
        </Col> */}
      </Row>
      {/* <Tabs
        style={{ marginTop: "0.5rem" }}
        defaultActiveKey="simple"
        items={tabItems}
        onChange={(key) => setState((prev) => ({ ...prev, activeTab: key }))}
      /> */}
      {/* <VectorSearchView
        searchText={state.searchText}
        enabled={true}
        favouritesMap={favourite.data}
        favouritesLoading={favourite.isLoading}
        onChangeFavourite={onChangeFavourite}
      /> */}

      {/* <div className="subtitles-list">
        {searchService.data?.results?.map((item) => (
          <SearchItem
            key={item._id}
            item={item}
            onSelect={(item) =>
              setState((prev) => ({ ...prev, selectItem: item }))
            }
          />
        ))}
      </div> */}

      <Tabs defaultActiveKey="0">
        <Tabs.TabPane
          // tab={
          //   <>
          //     <span className="pro-badge">Pro</span> Resources
          //   </>
          // }
          tab={"Standards-Aligned Best Practices"}
          key="0"
        >
          <ResourceView
            resources={ragSearch.data?.core_resources}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="General Resources" key="1">
          <ArticleView
            articles={ragSearch.data?.general_resources}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Web Resources" key="2">
          <BlogView
            blogs={ragSearch.data?.web_resources}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Your Articles" key="3">
          <ResourceView
            type="userArticle"
            resources={ragSearch.data?.user_articles || []}
            favouritesMap={favourite.data}
            favouritesLoading={favourite.isLoading}
            onChangeFavourite={onChangeFavourite}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
