import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  motion,
  useAnimate,
  useMotionValueEvent,
  useTime,
} from "framer-motion";
import "./index.css";
import AnimatedShinyText from "../../components/ui/shinyText";
import Meteors from "../../components/ui/meteros";
import { ShineBorder, BorderBeam } from "../../components/ui/shineBorder";
import { Button } from "antd";
import BlurFade from "../../components/ui/blurFade";

const images = ["/sla-app-1.png", "/sla-app-2.png", "/sla-app-1.png"];
const DURATION = 5000;
const ImageCard = ({ url, idx, total }) => {
  const time = useTime();
  const [scope, animate] = useAnimate();
  const [state, setState] = useState({ frame: 0 });

  //   const x = useTransform(time, [0, 1000, 2000], [0, 100, 0], { clamp: false });
  //   const y = useTransform(time, [0, 1000, 2000], [0, 100, 0], { clamp: false });

  useMotionValueEvent(time, "change", (latestTime) => {
    let globalTime = latestTime % (DURATION * total);
    latestTime = latestTime % DURATION;
    let frame = Math.floor(globalTime / DURATION);

    if (
      latestTime > 0 &&
      latestTime < DURATION + 1000 &&
      state.frame !== frame
    ) {
      console.log(frame);
      let newVals = {
        bottom: ((total + idx - frame) % total) * 50,
        left: ((total + idx - frame) % total) * 50,
        zIndex: (total + frame - idx - 1) % total,
      };

      animate(scope.current, {
        ...newVals,
        transition: {
          duration: 1,
        },
      });
      setState({ frame });
    }
  });

  return (
    <motion.img
      ref={scope}
      src={url}
      alt="School Leader's Advantage | App"
      className="absolute  rounded-lg shadow-xl border"
      style={{
        left: 50 * idx,
        bottom: 50 * idx,
        zIndex: total - idx,
        height: "50vh",
      }}
    />
  );
};

const Rocket01Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      opacity="0.4"
      d="M11.8013 6.48949L13.2869 5.00392C14.9596 3.3312 17.1495 2.63737 19.4671 2.52399C20.3686 2.47989 20.8193 2.45784 21.1807 2.81928C21.5422 3.18071 21.5201 3.63143 21.476 4.53289C21.3626 6.8505 20.6688 9.04042 18.9961 10.7131L17.5105 12.1987C16.2871 13.4221 15.9393 13.77 16.1961 15.097C16.4496 16.1107 16.6949 17.0923 15.9578 17.8294C15.0637 18.7235 14.2481 18.7235 13.354 17.8294L6.17058 10.646C5.27649 9.75188 5.27646 8.9363 6.17058 8.04219C6.90767 7.30509 7.88929 7.55044 8.90297 7.80389C10.23 8.06073 10.5779 7.71289 11.8013 6.48949Z"
      fill="currentColor"
    />
    <path
      d="M11.8013 6.48949L13.2869 5.00392C14.9596 3.3312 17.1495 2.63737 19.4671 2.52399C20.3686 2.47989 20.8193 2.45784 21.1807 2.81928C21.5422 3.18071 21.5201 3.63143 21.476 4.53289C21.3626 6.8505 20.6688 9.04042 18.9961 10.7131L17.5105 12.1987C16.2871 13.4221 15.9393 13.77 16.1961 15.097C16.4496 16.1107 16.6949 17.0923 15.9578 17.8294C15.0637 18.7235 14.2481 18.7235 13.354 17.8294L6.17058 10.646C5.27649 9.75188 5.27646 8.9363 6.17058 8.04219C6.90767 7.30509 7.88929 7.55044 8.90297 7.80389C10.23 8.06073 10.5779 7.71289 11.8013 6.48949Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M16.9959 7H17.0049"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 21.5L7.5 16.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.5 21.5L10.5 19.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2.5 15.5L4.5 13.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const Clock02Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <circle opacity="0.4" cx="12" cy="12" r="10" fill="currentColor" />
    <path
      d="M5.04798 8.60657L2.53784 8.45376C4.33712 3.70477 9.503 0.999914 14.5396 2.34474C19.904 3.77711 23.0904 9.26107 21.6565 14.5935C20.2227 19.926 14.7116 23.0876 9.3472 21.6553C5.36419 20.5917 2.58192 17.2946 2 13.4844"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8V12L14 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DashboardBrowsingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      opacity="0.4"
      d="M3.89124 20.1088C5.28249 21.5 7.52166 21.5 12 21.5C16.4783 21.5 18.7175 21.5 20.1088 20.1088C21.5 18.7175 21.5 16.4784 21.5 12C21.5 10.8633 21.5 9.87093 21.4773 9.00002H2.52275C2.5 9.87093 2.5 10.8633 2.5 12C2.5 16.4784 2.5 18.7175 3.89124 20.1088Z"
      fill="currentColor"
    />
    <path
      d="M2.5 12C2.5 7.52167 2.5 5.2825 3.89124 3.89126C5.28249 2.50002 7.52166 2.50002 12 2.50002C16.4783 2.50002 18.7175 2.50002 20.1088 3.89126C21.5 5.2825 21.5 7.52167 21.5 12C21.5 16.4784 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4784 2.5 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M2.5 9.00002H21.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M6.99981 6.00002H7.00879"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9998 6.00002H11.0088"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17C17 14.2386 14.7614 12 12 12C9.23858 12 7 14.2386 7 17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.707 15.293L11.2928 16.7072"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckmarkCircle02Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      opacity="0.4"
      d="M12 22.75C6.06294 22.75 1.25 17.9371 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7372 9.67573C17.1103 9.26861 17.0828 8.63604 16.6757 8.26285C16.2686 7.88966 15.636 7.91716 15.2628 8.32428L10.4686 13.5544L8.70711 11.7929C8.31658 11.4024 7.68342 11.4024 7.29289 11.7929C6.90237 12.1834 6.90237 12.8166 7.29289 13.2071L9.79289 15.7071C9.98576 15.9 10.249 16.0057 10.5217 15.9998C10.7944 15.9938 11.0528 15.8768 11.2372 15.6757L16.7372 9.67573Z"
      fill="currentColor"
    />
  </svg>
);

const FeatureElem2 = ({ title, caption, icon }) => {
  return (
    <div className="flex flex-col md:flex-row gap-6 items-center md:items-start">
      <div className=" shrink-0">
        {icon({ className: "h-24 w-24 text-mandy-400" })}
      </div>

      <div>
        <div className="text-3xl text-mandy-400 text-center md:text-left">{title}</div>
        <div className="text-mandy-400 mt-2 text-lg  text-center md:text-left">{caption}</div>
      </div>
    </div>
  );
};

const FeaturesItem = ({ imgPath, captionText }) => {
  return (
    <div className="basis-1/3 flex flex-col md:h-[18rem] justify-between items-center">
      <div className="h-32 w-32  flex-0 rounded-full overflow-hidden flex items-center justify-center bg-gradient-to-b from-mandy-400 from-50% to-mandy-300 relative">
        <div
          style={{
            backgroundImage: "url('light_toast.webp')",
            mixBlendMode: "multiply",
            zIndex: 0,
          }}
          alt="bg"
          className="h-full  w-full absolute top-0 left-0"
        />
        <img src={imgPath} alt="Header Icon 1" className="h-20 w-20" />
      </div>
      <div className="flex-1 flex flex-col justify-center mt-8 px-4 max-w-[32rem]">
        <p className="text-center mx-8 text-lg  text-neutral-600">
          {captionText}
        </p>
      </div>
    </div>
  );
};

const LandingPage = () => {
  const navigate = useNavigate();

  // const isInView = !inView || inViewResult;
  const defaultVariants = {
    hidden: { y: 6, opacity: 0, filter: `blur(6)` },
    visible: { y: -6, opacity: 1, filter: `blur(0px)` },
  };

  return (
    <div className="landing relative">
      <div
        className="h-auto md:h-screen w-screen hero"
        style={
          {
            // backgroundImage: "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)",
            // backgroundImage: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
          }
        }
      >
        <div className="w-screen  h-16 absolute top-0 z-20">
          <div className="flex max-w-screen-xl w-full  mx-auto justify-between items-center px-4 md:px-16 py-4">
            <div class="w-48">
              <img src="/logo.png" alt="School Leader's Advantage | Logo" />
            </div>
            <div>
              <button
                className="bg-transparent-500 transition border border-endeavour-100 hover:bg-endeavour-600 text-endeavour-100 font-bold py-2 px-4 rounded-xl mt-[4px]"
                onClick={() => navigate("/signin")}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundBlendMode: "overlay",
          }}
        >
          <div
            className="md:h-screen min-h-screen w-screen absolute top-0 bg-repeat"
            style={{
              backgroundImage: "url('light_toast.webp')",
              // backgroundSize: "cover",
              // filter: "blur(5px)",
              zIndex: 0,
              // backgroundBlendMode: "overlay",
            }}
          ></div>

          <div
            className="md:h-screen min-h-screen w-screen absolute top-0 bg-repeat gradient"
            style={{
              // backgroundImage: "url('light_toast.webp')",
              // backgroundSize: "cover",
              // filter: "blur(5px)",
              // backgroundBlendMode: "overlay",
              mixBlendMode: "multiply",
              zIndex: 1,
            }}
          ></div>
        </div>
        <div className="max-w-screen-2xl mx-auto px-4 flex justify-between h-16 items-center">
          <img
            onClick={() => navigate("/")}
            src="/logo.png"
            alt="School Leader's Advantage | Logo"
            style={{ height: "50px", cursor: "pointer" }}
          />

          <button
            onClick={() => navigate("/signup")}
            className="bg-endeavour-500 transition hover:bg-endeavour-600 text-white font-bold py-2 px-4 rounded-xl"
          >
            Get Started
          </button>
        </div>
        <div className="md:h-screen md:max-h-[70rem] max-w-screen-2xl mx-auto flex items-center justify-center relative z-10">
          <Meteors number={30} />
          <div className=" mx-auto flex flex-col pt-12 pb-[12rem] items-center">
            {/* <BlurFade delay={0} duration={0.75}>
              <div>
                <AnimatedShinyText className="my-4 border dark:border-endeavour-100 border-endeavour-100 rounded-full  px-4 py-1">
                  ✨ Introducing SLA App
                </AnimatedShinyText>
              </div>
            </BlurFade> */}
            <BlurFade duration={0.75}>
              <div className="max-w-screen-xl bg-gradient-to-br from-white from-40% to-endeavour-200 bg-clip-text mx-auto px-4 flex justify-between items-center md:text-7xl md:leading-[5rem] text-4xl leading-[125%]  font-semibold text-center  text-transparent pt-6">
                Empower Your School's Success with Evidence-Based Leadership
              </div>
            </BlurFade>
            <BlurFade delay={0.2} duration={0.75}>
              <div className="max-w-screen-lg mx-auto px-4 flex justify-between items-center text-xl md:text-2xl  text-endeavour-50 mt-8 text-center">
                School Leader's Advantage is the first digital platform to offer
                standards-aligned best practices for K-12 school leaders. Get
                quick and reliable access to the latest research and tools to
                enhance your administrative and instructional leadership skills.
              </div>
            </BlurFade>

            <BlurFade delay={0.4} duration={0.75}>
              <button className="bg-white transition  text-endeavour-600 border border-endeavour-600 font-semibold py-2 mx-auto px-6 rounded-xl mt-24 h-12 w-48" 
                onClick={() => navigate("/signup")}
              >
                Get Started Now
              </button>
            </BlurFade>
          </div>
        </div>
        {/* <h1>Landing Page</h1> */}

        <div className="max-w-screen-xl mx-auto relative md:h-[40rem] px-4">
          <BlurFade delay={0.6} duration={0.75}>
            {/* <div> */}
            <ShineBorder
              className="rounded-lg overflow-clip glow-shadow relative md:top-[-10rem] md:h-[40rem]  flex-col items-center justify-center overflow-hidden rounded-lg border bg-transparent "
              color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
            >
              <img
                src="/sla-app-1.png"
                alt="School Leader's Advantage | App"
                className="w-full "
              />
            </ShineBorder>
            {/* </div> */}
          </BlurFade>
          <div className="relative bottom-0">
            <BlurFade delay={0.6} duration={0.35} inView={true}>
              <h2 className="text-endeavour-500 md:text-4xl font-semibold text-left md:leading-[3rem]  text-2xl pt-8 md:pt-0">
                Explore the Best Practices for Your School in Standards aligned
                Research Articles and Web Resources
              </h2>{" "}
            </BlurFade>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto relative md:h-[54rem] my-32 flex flex-col">
          <BlurFade delay={0} duration={0.35} inView={true}>
            <div className="text-center text-4xl font-semibold text-endeavour-600">
              How it Works
            </div>
          </BlurFade>

          <div className="flex flex-col md:flex-row justify-between items-center gap-12 mt-20">
            <BlurFade delay={0} duration={0.35} inView={true}>
              <FeaturesItem
                imgPath={"/home/header-icon-1.svg"}
                captionText="Choose from our library of evidence-based leadership standards."
              />
            </BlurFade>
            <BlurFade delay={0.1} duration={0.35} inView={true}>
              <FeaturesItem
                imgPath={"/home/header-icon-2.svg"}
                captionText="SLA scans selected portions of the web and other sources to match you with high-quality, evidence-based best practice."
              />
            </BlurFade>
            <BlurFade delay={0.2} duration={0.35} inView={true}>
              <FeaturesItem
                imgPath={"/home/header-icon-3.svg"}
                captionText="Use results to improve your school administrative and instructional leadership skills."
              />
            </BlurFade>
          </div>
          <div className="flex flex-col md:flex-row  justify-around items-center mt-16  gap-12 md:px-12">
            <BlurFade delay={0.3} duration={0.35} inView={true}>
              <FeaturesItem
                imgPath={"/home/header-icon-4.svg"}
                captionText="Archive evidence-based best practices for future application in your school."
              />
            </BlurFade>
            <BlurFade delay={0.4} duration={0.35} inView={true}>
              <FeaturesItem
                imgPath={"/home/header-icon-5.svg"}
                captionText="Using state-of-the-art Artificial Intelligence (AI), our 'Answer Bot' will respond to your most challenging school leadership questions."
              />
            </BlurFade>
          </div>
        </div>

        <BlurFade delay={0.2} duration={0.35} inView={true}>
          <div className="max-w-screen-xl flex flex-col md:flex-row mx-auto relative  my-24 gap-16 md:h-[54rem]">
            <div className="basis-1/3 flex flex-col px-4 md:px-0">
              <div className="text-center text-4xl font-semibold text-endeavour-600 leading-[2.75rem]">
                Implementation, Tracking & Evaluation Module (ITEM)
              </div>

              <div className="flex flex-col gap-8 mt-16 px-4 md:px-0">
                <div className="flex items-center gap-4">
                  <div className="bg-endeavour-500 h-10 w-10 p-2 rounded-full flex-0 shrink-0	">
                    <img src="/home/toolset-icon-1.svg" alt="Toolset Icon 1" />
                  </div>
                  <div>
                    <p className="text-neutral-600 text-lg ">
                      Delegate best practice-related tasks to faculty and other
                      school community members.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="bg-endeavour-500 h-10 w-10 p-2 rounded-full flex-0 shrink-0	">
                    <img src="/home/toolset-icon-2.svg" alt="Toolset Icon 1" />
                  </div>
                  <div>
                    <p className="text-neutral-600 text-lg ">
                      Establish goals, objectives, milestones, and outcomes.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="bg-endeavour-500 h-10 w-10 p-2 rounded-full flex-0 shrink-0	">
                    <img src="/home/toolset-icon-3.svg" alt="Toolset Icon 1" />
                  </div>
                  <div>
                    <p className="text-neutral-600 text-lg ">
                      Document the outcome of best practice in your school.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="basis-2/3 relative  px-4 w-screen md:w-auto">
              <img
                src="/sla-app-2.png"
                alt="School Leader's Advantage | App"
                className="md:h-full w-full md:w-auto relative rounded-lg overflow-clip glow-shadow w-auto max-w-none left-0 top-0"
                style={{
                  top: "0 !important",
                }}
              />
            </div>
          </div>
        </BlurFade>

        <div className="w-screen md:h-screen my-24 py-24 gap-16 bg-endeavour-800">
          <BlurFade
            delay={0.2}
            duration={0.35}
            inView={true}
            className="h-full w-full"
          >
            <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row h-full">
              <div className="basis-1/2 h-full flex flex-col justify-center items-center">
                <div className="bg-gradient-to-br from-endeavour-100 from-40% to-endeavour-300 bg-clip-text mx-auto px-4 flex justify-between items-center  font-semibold text-center text-3xl leading-[130%] md:text-6xl  md:leading-[5rem] text-transparent">
                  {" "}
                  The First AI tool for school leaders
                </div>

                <div className="max-w-screen-lg mx-auto px-12 py-8 mt-8 flex justify-between items-center text-lg md:text-base  text-endeavour-50 text-center opacity-60">
                  School Leader's Advantage is the first digital platform to
                  offer standards-aligned best practices for K-12 school
                  leaders.
                </div>
              </div>
              <div className="basis-1/2 h-full flex items-center justify-center gap-4 px-8 mt-8 md:mt-0">
                <div className="flex flex-col gap-12">
                  <FeatureElem2
                    title="Quick & Reliable"
                    caption="Get the best practice you need, without having to sort through pages of traditional search engine results."
                    icon={(props) => <Rocket01Icon {...props} />}
                  />
                  <FeatureElem2
                    title="Powerful tools"
                    caption="As your personal research assistant, School Leader’s Advantage connects you with the latest evidence-based best practice and provide tools which facilitate their implementation."
                    icon={(props) => <DashboardBrowsingIcon {...props} />}
                  />
                  <FeatureElem2
                    title="Available anytime"
                    caption="School Leader's Advantage provides 24/7, on-demand results. Use from your desktop PC or mobile data device."
                    icon={(props) => <Clock02Icon {...props} />}
                  />
                </div>
              </div>
            </div>
          </BlurFade>
        </div>

        <BlurFade delay={0.2} duration={0.35} inView={true}>
          <div className="w-screen md:h-auto  bg-gradient-to-b from-[#e9eaed]  via-[#7bc2fec7] to-[#e9eaed]">
            <div className="max-w-screen-xl flex flex-col mx-auto relative  my-32 gap-16  ">
              <div className="text-center text-4xl font-semibold text-endeavour-600">
                Industry Acknowledged
              </div>

              <div className="flex flex-col justify-between items-center gap-24 mt-12 md:px-24 px-12  ">
                <div className="flex flex-col items-center">
                  <img src="/home/acknowledge-image-1.png" alt="Industry 1" />
                  <p className="text-center text-lg text-neutral-600 mt-4">
                    Education Technology Industry Network of SIIA (ETIN)
                    Incubator
                  </p>
                </div>

                <div className="flex flex-col items-center">
                  <img src="/home/acknowledge-image-2.png" alt="Industry 2" />
                  <p className="text-center text-lg text-neutral-600 mt-4 md:px-20">
                    ETIN/SIIA Innovation Incubator Semi-Finalist
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BlurFade>

        <BlurFade delay={0.2} duration={0.35} inView={true}>
          <div className="max-w-screen-xl flex flex-col mx-auto relative  my-32 gap-16 md:h-[50rem] ">
            <div className="text-center text-4xl font-semibold text-endeavour-600">
              Pricing
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center gap-6 mt-12 md:px-24  px-8 ">
              <div className="flex flex-col rounded-xl bg-white basis-1/3 md:h-[34rem] p-3 px-8">
                <p className="text-left text-2xl text-neutral-600 font-medium mt-4">
                  Single User Monthly
                </p>

                <div className="flex flex-col items-left">
                  <div className="text-left text-lg text-endeavour-600 mt-10 flex gap-2  items-end">
                    <span className="text-4xl font-semibold"> $29.99  </span>{" "}
                    <span className="text-endeavour-600 text-sm pb-1">
                      per month
                    </span>
                  </div>
                </div>
                <div className="text-base text-neutral-600 text-left mt-8 h-20">
                  For a sole school leader (e.g., superintendent, principal, or
                  vice principal).
                </div>

                <div className="flex flex-col items-left mt-10 h-36">
                  <p className="text-neutral-600 text-base inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Access to all platform features.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Customized setup & training.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    On-demand technical support.
                  </p>
                </div>

                <div className="flex flex-col items-center mt-10 bottom-0">
                  <Link to="/signup" className="w-full">
                    <button className="border border-endeavour-500 transition hover:border-endeavour-600 text-endeavour-600 font-bold py-2 px-4 rounded-xl w-full">
                      Sign Up
                    </button>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col rounded-xl bg-white basis-1/3  md:h-[34rem] p-3 px-8 ">
                <p className="text-left text-2xl text-neutral-600 font-medium mt-4">
                  Single User Annually
                </p>

                <div className="flex flex-col items-left">
                  <div className="text-left text-lg text-endeavour-600 mt-10 flex gap-2">
                    <span className="text-4xl font-semibold"> $23.99  </span>{" "}
                    <span className="text-endeavour-600 text-sm">
                      per month, billed annually $287.88
                    </span>
                  </div>
                </div>
                <div className="text-base text-neutral-600 text-left mt-8 h-20">
                  For a sole school leader (e.g., superintendent, principal, or
                  vice principal).
                </div>

                <div className="flex flex-col items-left mt-10 h-36">
                  <p className="text-neutral-600 text-base inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Access to all platform features.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Customized setup & training.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    On-demand technical support.
                  </p>
                </div>

                <div className="flex flex-col items-center mt-10 bottom-0">
                  <Link to="/signup" className="w-full">  
                    <button className="bg-endeavour-500 transition hover:bg-endeavour-600 text-white font-bold py-2 px-4 rounded-xl w-full">
                    Sign Up
                  </button>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col rounded-xl bg-white basis-1/3  md:h-[34rem] p-3 px-8 ">
                <p className="text-left text-2xl text-neutral-600 font-medium mt-4">
                  Multiple Users
                </p>

                <div className="flex flex-col items-left">
                  <p className="text-left text-lg text-endeavour-600 mt-10 h-10">
                    {/* <span className="text-3xl font-semibold"> $9.99 </span>{" "} */}
                    <span className="text-endeavour-600">
                      Custom Pricing for multiple users
                    </span>
                  </p>
                </div>
                <div className="text-base text-neutral-600 text-left mt-8 h-20 ">
                  For multiple school leaders or other staff occupying the same
                  building.
                </div>

                <div className="flex flex-col items-left mt-10 md:h-36">
                  <p className="text-neutral-600 text-base inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Access to all platform features.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Customized setup & training.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    On-demand technical support.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Free Upgrades to new features.
                  </p>
                  <p className="text-neutral-600 text-base mt-2  inline-flex gap-2">
                    <CheckmarkCircle02Icon className="h-6 w-6 text-endeavour-600" />{" "}
                    Custom data integration
                  </p>
                </div>

                <div className="flex flex-col items-center mt-10 bottom-0">
                  <Link to="/contact" className="w-full">
                    <button className="border border-endeavour-500 transition hover:border-endeavour-600 text-endeavour-600 font-bold py-2 px-4 rounded-xl w-full">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </BlurFade>

        <BlurFade delay={0.2} duration={0.35} inView={true}>
          <div className="max-w-screen-xl flex flex-col mx-auto relative  my-32 gap-16 bg-endeavour-50 py-12 rounded-2xl">
            <div className="text-center text-4xl font-semibold text-endeavour-600 pt-12">
              Powered by most powerful AI Available
            </div>

            <div className="flex md:flex-row flex-col justify-between items-center md:gap-6 gap-24 py-24 px-24  ">
              <img
                src="/openai-logos/SVGs/openai-lockup.svg"
                alt="AI Image 1"
                className="h-16"
              />
              <img src="/gemini-logo.png" alt="AI Image 1" className="h-16" />
              <img src="/Anthropic_logo.svg" alt="AI Image 1" className="h-8" />
            </div>
          </div>
        </BlurFade>

        <div className="w-screen bg-endeavour-800 md:h-[32rem]">
          <div className="max-w-screen-xl mx-auto px-12">
            <div className="flex md:flex-row flex-col mx-auto py-16 w-full justify-between">
              <div className="flex flex-col gap-8 text-endeavour-200">
                <div>
                  <img
                    src="/logo.png"
                    alt="School Leader's Advantage | Logo"
                    // style="height: 50px; margin: auto 0px;"
                    style={{ height: "50px", margin: "auto 0px" }}
                  ></img>
                </div>

                <p className="max-w-96">
                  The first digital tool to offer standards-aligned best
                  practices for leaders of K-12 schools.
                </p>
              </div>
              <div class="flex flex-col gap-4 text-endeavour-100 text-left mt-12 md:mt-0">
                <img
                  src="/patent-pending.png"
                  alt="Patent Pending"
                  style={{
                    // "width: 64px; border-radius: 10%;"

                    width: "128px",
                    borderRadius: "10%",
                  }}
                />
                <a href="/terms-service" className="text-endeavour-100">
                  Terms of Service
                </a>
                <a href="/privacy-policy" className="text-endeavour-100">
                  Privacy Policy
                </a>
                <a href="/contact" className="text-endeavour-100">
                  Contact Us
                </a>
                <a href="/dmca" className="text-endeavour-100">
                  DMCA Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
