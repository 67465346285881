import { axios } from "./api";

const baseUrl = process.env.REACT_APP_API_URL;

export class BaseCRUDService {
  constructor(base) {
    this.base = `${baseUrl}/${base}`;
  }

  getAll = async (params) => {
    return axios
      .get(`${this.base}/`, { params: params })
      .then((res) => res.data);
  };

  get = async (id, params) => {
    return axios.get(`${this.base}/${id}/`, {params: params}).then((res) => res.data);
  };

  create(data) {
    return axios.post(`${this.base}/`, data).then((res) => res.data);
  }

  update(id, data) {
    return axios.put(`${this.base}/${id}/`, data).then((res) => res.data);
  }

  delete(id) {
    return axios.delete(`${this.base}/${id}/`).then((res) => res.data);
  }

  deleteAll() {
    return axios.delete(`${this.base}/`).then((res) => res.data);
  }
}
