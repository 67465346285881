import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  notification,
  Radio,
  Typography,
  Spin,
} from "antd";
import { Link } from "react-router-dom";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const CustomInput = (props) => {
  return (
    <div>
      <Input.Password {...props} />
      <Link to="/forgot-password">
        <Button size="small" type="link">
          Forgot Password?
        </Button>
      </Link>
    </div>
  );
};

const SignInForm = (props) => {
  const [form] = Form.useForm();
  const [loading, updateLoading] = useState(false);

  const openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  const onFinish = (values) => {
    updateLoading(true);
    props
      .onSubmit(values.username, values.password)
      .then(() => {
        openNotification("success", "Login Successful");
        updateLoading(false);
      })
      .catch((err) => {
        openNotification(
          "error",
          "Login Failed",
          JSON.stringify(err.response.data?.detail)
        );
        updateLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        size="large"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="flex flex-col gap-2">
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <CustomInput />
          </Form.Item>

          <div style={{ marginTop: "0.5rem" }}>
            <Form.Item>
              <button
                disabled={loading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%", height: "44px" }}
                className="bg-endeavour-600 hover:bg-endeavour-700 transition rounded-xl text-white px-2 py-1"
              >
                {loading ? <i className="fas fa-spinner fa-spin text-white"></i> : "Login"}
              </button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="mt-4">
        <Typography.Text type="secondary">
          Don't have an account?
          <Link to="/signup">
            {" "}
            <a style={{ color: "var(--primary)" }}>Sign Up</a>
          </Link>
        </Typography.Text>
      </div>
    </>
  );
};

export default SignInForm;
