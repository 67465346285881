import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ChatbotSession, ChatMessageService } from "../../services/dataService";
import { Button, Col, Row, message, Input, Divider, Typography } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { MoreOutlined, SendOutlined, LoadingOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";

import getWebsocket from "./websocket";
import { useAuth } from "../../authContext";
import "./index.css";

const ChatBot = (props) => {
  const { authToken } = useAuth();
  const [firstMessage, setFirstMessage] = useState(props.firstMessage);
  const [state, setState] = useState({});
  const SessionService = ChatbotSession;
  const [messages, setMessages] = useState([]);
  let chatWebsocket = useRef();
  let dummyDiv = useRef();

  useEffect(() => {
    if (dummyDiv.current) {
      dummyDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.messages]);

  const scrollLastMessage = () => {
    if (dummyDiv.current) {
      dummyDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    initChatsocket(authToken);
  }, []);

  useEffect(() => {
    if (props.sessionId != state.sessionId) {
      setState((prev) => ({ ...prev, sessionId: props.sessionId }));
      chatbotMessages.mutate(props.sessionId);
    }
  }, [props.sessionId]);

  const handleNewMessage = (event) => {
    const msg = JSON.parse(event.data);
    if (msg.event == "message_update") {
      if (msg.message) {
        setMessages((prev) =>
          (prev || []).map((item) =>
            item.id == msg.id
              ? { ...item, loading: msg.loading, message: msg.message }
              : { ...item }
          )
        );
      }
      scrollLastMessage();
    } else if (msg.event === "resources") {
      if (props.addResources) {
        props.addResources(msg.resources);
      }
    } else if (msg.event === "user_message") {
      setMessages((prev) => [...prev, { ...msg }]);
      setState((prev) => {
        return {
          ...prev,
          message: "",
        };
      });
    } else if (msg.event === "resources") {
      setState((prev) => ({ ...prev, resources: msg.message }));
    } else if (msg.event === "agent_message") {
      setMessages((prev) => [...prev, { ...msg }]);

      setState((prev) => {
        return {
          ...prev,
          message: "",
        };
      });
    }
  };

  const initChatsocket = (token, flag) => {
    try {
      message.destroy();
      if (flag == "reconnect") {
        message.loading("Re-Connecting to chatbot...", 0);
      } else {
        message.loading("Connecting to chatbot...", 0);
      }
      let wsURL = props.isPublic
        ? process.env.REACT_APP_WS_URL.replace("api", "api/public")
        : process.env.REACT_APP_WS_URL;
      chatWebsocket.current = getWebsocket({
        protocol: "normal",
        sessionId: props.sessionId,
        token: token,
        onOpen: () => {
          setState((prev) => ({ ...prev, websocketState: "open" }));
          checkPendingMessage();
          message.destroy();
        },

        baseUrl: props.v2
          ? wsURL + `/chatbot/session/${props.sessionId}/ws_v2/`
          : wsURL + `/chatbot/session/${props.sessionId}/ws/`,
      });
      chatWebsocket.current.onmessage = handleNewMessage;
      chatWebsocket.current.onclose = () => {
        console.log("Websocket closed");
        setState((prev) => ({ ...prev, websocketState: "closed" }));
      };
      window.addEventListener("focus", checkWebsocket);
    } catch (exception) {
      console.log(exception);
      debugger;
    }
  };

  const checkPendingMessage = () => {
    if (firstMessage && messages.length === 0) {
      // If first message is present, send it to the chatbot
      chatWebsocket.current.send(
        JSON.stringify({
          event: "new_message",
          type: "user",
          message: firstMessage,
          session_id: props.sessionId,
        })
      );

      setFirstMessage(null);
    }
  };

  const checkWebsocket = () => {
    if (chatWebsocket.current.readyState === 3) {
      initChatsocket(authToken, "reconnect");
    }
  };

  const chatbotMessages = useMutation(
    (sessionId) => SessionService.get_messages(sessionId),
    {
      onSuccess: (data) => {
        message.success("Messages Loaded");
        setMessages([...data.results]);
        setState((prev) => ({ ...prev, messages: [...data.results] }));
      },
      onError: (data) => {
        message.error("Messages Loading Failed!!!");
      },
    }
  );

  const newMessage = async () => {
    await chatWebsocket.current.send(
      JSON.stringify({
        event: "new_message",
        type: "user",
        message: state.message,
        session_id: props.sessionId,
      })
    );
    setState((prev) => ({ ...prev, message: "" }));
  };

  return (
    <Row className="h-full chat-window w-full">
      <div className="h-full w-full mx-auto relative overflow-clip">
        <div
          className="h-full pb-16 w-full py-2"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="chat-message-box pr-4 py-2">
            {/* <Divider type="horizontal">Conversation Started</Divider> */}
            {(messages || []).map((item) => (
              <div key={item.id}>
                <div className="chat-message-wrapper" key={item.id}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // float: item.is_agent ? "left" : "right",
                      // maxWidth: "60%",
                      margin: "0.5rem 0rem",
                    }}
                  >
                    <div
                      className={
                        item.is_agent
                          ? "agent-chat-message message-container"
                          : "user-chat-message message-container"
                      }
                    >
                      {item.is_agent ? (
                        <i class="fa-solid fa-robot chat-avatar"></i>
                      ) : (
                        <i class="fa-solid fa-user chat-avatar"></i>
                      )}
                      {item.is_agent ? (
                        <span className="agent-name message-label">SLA</span>
                      ) : (
                        <span className="user-name message-label">You</span>
                      )}
                    </div>
                    <div className="message-content">
                      {item.loading ? (
                        <LoadingOutlined />
                      ) : (
                        <div className="markdown-message-container	">
                          <ReactMarkdown
                            components={{
                              ul: ({ node, ...props }) => (
                                <ul {...props} className="list-disc" />
                              ),
                              ol: ({ node, ...props }) => (
                                <ol {...props} className="list-decimal" />
                              ),
                              li: ({ node, ...props }) => (
                                <li {...props} className="ml-4 my-2" />
                              ),
                              p: ({ node, ...props }) => (
                                <p {...props} className="my-2" />
                              ),
                            }}
                          >
                            {item.message}
                          </ReactMarkdown>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={dummyDiv} style={{ paddingBottom: "1rem" }}></div>
            {/* </div> */}
          </div>
        </div>

        <div className="h-12 absolute bottom-0 w-full ">
          <Input
            className="w-full h-full p-2 "
            value={state.message}
            onChange={(e) =>
              setState((prev) => ({ ...prev, message: e.target.value }))
            }
            onPressEnter={newMessage}
            suffix={
              // <Button
              //   loading={newMessage.isLoading}
              //   onClick={newMessage}
              //   // type="primary"
              //   type="link"
              //   className="h-full"
              //   size="small"
              // >
              //   <SendOutlined />
              // </Button>
              <button
                className={`hover: text-white px-4 py-2 rounded-xl text-sm ${
                  state.message != "" ? "bg-endeavour-400" : "bg-endeavour-200"
                }`}
                onClick={() => (state.message != "" ? newMessage() : null)}
              >
                <i class="fa-solid fa-paper-plane"></i>
              </button>
            }
          ></Input>
        </div>
      </div>
    </Row>
  );
};

export default ChatBot;
