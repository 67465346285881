import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { useFind } from "meteor/react-meteor-data";
// import { hasRights } from "/imports/ui/components/Functions";
import { OpenIcon } from "../../components/Icons.jsx";
// import StateDropdown from "/imports/ui/standards/StateDropdown.jsx";
import { useQuery } from "react-query";
import { BotService, StandardService } from "../../services/dataService";
import { Button, Card, Col, Spin, Typography, Space } from "antd";
import "./subtitles.css";

export default function Subtitles({ status, setStatus, isState }) {
  const { id } = useParams();

  const navigate = useNavigate();
  const subtitles = useQuery("subtitles", () =>
    StandardService.getSubtitles(id)
  );

  const standard = useQuery("standard", () => StandardService.get(id));

  return (
    <div className="wrapper subtitles">
      <div className="header">
        <Col>
          <div className="breadcrumbs">
            <span onClick={() => navigate("/standards")}>Standards</span>
          </div>
          {/* <Typography.Title level={4}>{standard.data?.title}</Typography.Title>
           */}
          <div className="text-2xl font-medium text-endeavour-600 mb-2">
            {standard.data?.title}
          </div>
        </Col>
      </div>

      {/* {status.showCategoryDropdown && <StateDropdown status={status} setStatus={setStatus} />} */}

      <div className="subtitles-list">
        {subtitles.data?.results?.map((subtitle) => (
          <SubtitleItem
            key={subtitle.id}
            subtitle={subtitle}
            status={status}
            setStatus={setStatus}
            isState={isState}
          />
        ))}
      </div>
      {subtitles.isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "400px",
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}

function SubtitleItem({ subtitle, status, setStatus, isState }) {
  const navigate = useNavigate();

  const { id, content } = subtitle;

  const handleDocuments = () => {
    // debugger
    // if (!isPayer) navigate('/payment');
    setStatus({ ...status, view: "documents", subtitleId: id });
  };

  const chatHandover = async () => {
    // Create a new session
    let res = await BotService.createChatSession({
      subtitleId: id,
      title: content,
    });

    navigate(`/chat/${res.id}`, {
      state: {
        sessionId: res.id,
        firstMessage: `Tell me more about educational standard: How to ${content}`,
      },
    });
  };

  return (
    <Card>
      <div className="card subtitle">
        <Typography.Paragraph>{content}</Typography.Paragraph>

        <div className="actions">
          <Space direction="horizontal">
            <Link to={ isState ? `/state-subtitle/v2/${id}` : `/subtitle/v2/${id}`}>
              <Button type="text" className="text-action-btn" size="small">
                <i class="fa-solid fa-magnifying-glass button-icon"></i> View
                Resources
              </Button>
            </Link>
            {/* <Link to={`/search?searchText=${content}`}> */}
            <Button
              type="text"
              className="text-action-btn"
              size="small"
              onClick={chatHandover}
            >
              <i class="fa-regular fa-message button-icon"></i> Chat
            </Button>
            {/* </Link> */}
          </Space>

          {/* <button onClick={handleDocuments} className="button secondary">
          {isPayer ? "Explore" : "Upgrade Now"}
        </button> */}
        </div>
      </div>
    </Card>
  );
}
