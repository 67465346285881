import React, { useState } from "react";
import { Form, Input, Button, notification, Radio, Checkbox, Typography } from "antd";
import { Link } from "react-router-dom";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SignUpForm = (props) => {
  const [form] = Form.useForm();
  const [loading, updateLoading] = useState(false);

  const openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  const onFinish = (values) => {
    var name = values.name;
    var [first_name, ...last_names] = name.split(" ");
    var last_name = last_names.join(" ");

    updateLoading(true);
    props
      .onSubmit(
        first_name,
        last_name,
        values.password,
        values.email,
        values.role
      )
      .then(() => {
        updateLoading(false);
        form.resetFields();
        openNotification("success", "Signup Successful", "Please login now");
      })
      .catch((error) => {
        updateLoading(false);
        openNotification(
          "error",
          "Signup Failed",
          JSON.stringify(error.response.data)
        );
      });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      size="large"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      // initialValues={{ role: "foodie" }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input your name!" }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please enter your email!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Renter Password"
        name="password2"
        rules={[{
          validator: (_, value) => {
            if (value !== form.getFieldValue('password')) {
              return Promise.reject(new Error('Passwords do not match'));
            }else if (!value) {
              return Promise.reject(new Error('Password is required'));
            }
            return Promise.resolve();
          }

         }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="accept"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Please accept.")),
          },
        ]}
        // {...tailLayout}
      >
        <Checkbox>
          I accept the{" "}
          <Link to="/terms-service">
            <a style={{ color: "var(--primary)" }}>Terms & Conditions</a>
          </Link>{" "}
          and{" "}
          <Link to="/privacy-policy">
            <a style={{ color: "var(--primary)" }}>Privacy Policy</a>
          </Link>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        {/* <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%", height: "44px"}}
        >
         
        </Button> */}
        <button
                disabled={loading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%", height: "44px" }}
                className="bg-endeavour-600 hover:bg-endeavour-700 transition rounded-xl text-white px-2 py-1"
              >
                {loading ? <i className="fas fa-spinner fa-spin text-white"></i> : " Create Account"}
              </button>
      </Form.Item>
      <Typography.Text type="secondary">
        Have an account?{" "}
        <Link to="/signin">
          {" "}
          <a style={{ color: "var(--primary)" }}>Sign In</a>
        </Link>
      </Typography.Text>
    </Form>
  );
};

export default SignUpForm;
