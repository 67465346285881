import { BaseCRUDService } from "./baseService";
import { axios } from "./api";
import axios_ from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

class StandardCRUDService extends BaseCRUDService {
  getSubtitles(id, params) {
    return axios
      .get(`${this.base}/${id}/subtitles/`, { params: params })
      .then((res) => res.data);
  }

  search(subTitleId, params) {
    return axios
      .get(`${this.base}/subtitle/${subTitleId}/search/`, { params: params })
      .then((res) => res.data);
  }

  getSubtitle(subTitleId) {
    return axios
      .get(`${this.base}/subtitle/${subTitleId}/`)
      .then((res) => res.data);
  }
}

class SearchCRUDService extends BaseCRUDService {
  simpleSearch(params) {
    return axios
      .get(`${this.base}/simple/`, { params: params })
      .then((res) => res.data);
  }

  vectorSearch(params) {
    return axios
      .get(`${this.base}/vector/`, { params: params })
      .then((res) => res.data);
  }

  ragSearch (params) {
    return axios
      .get(`${this.base}/rag-search/`, { params: params })
      .then((res) => res.data);
  }

  ragSearchV2 (params) {
    return axios
      .get(`${this.base}/rag-search-v2/`, { params: params })
      .then((res) => res.data);
  }

  getKeywordSuggestions(params) {
    return axios
      .get(`${this.base}/keyword-suggestions/`, { params: params })
      .then((res) => res.data);
  }

  customSearch(params) {
    return axios
      .get(`${this.base}/custom-search/`, { params: params })
      .then((res) => res.data);
  }

  getDocument(id, resource, responseType = "blob") {
    return axios.get(`${this.base}/document/`, {
      params: { id: id, type: resource || "resource" },
      responseType: responseType,
    }).then((res) => res.data);
  }
}

class PaymentService_ extends BaseCRUDService {
  createSubscription(data) {
    return axios
      .post(`${this.base}/create-subscription/`, data)
      .then((res) => res.data);
  }

  getChargebeeCheckoutLink(data) {
    return axios
      .post(`${this.base}/get-chargebee-checkout-url/`, data)
      .then((res) => res.data);
  }

  getChargebeePortalLink(data) {
    return axios
      .get(`${this.base}/customer-portal/`, { params: data })
      .then((res) => res.data);
  }
}

class FavouriteService_ extends BaseCRUDService {
  getAllMap(data) {
    return axios.get(`${this.base}/map/`, data).then((res) => res.data);
  }
}

class UserService_ extends BaseCRUDService {
  getAllUsers() {
    return axios.get(`${this.base}/all/`).then((res) => res.data);
  }

  changePassword(data) {
    return axios
      .post(`${this.base}/reset_password/`, data)
      .then((res) => res.data);
  }

  forgotPassword(data) {
    return axios
      .post(`${this.base}/forgot_password/`, data)
      .then((res) => res.data);
  }

  confirmForgotPassword(data) {
    return axios
      .post(`${this.base}/confirm_forgot_password_token/`, data)
      .then((res) => res.data);
  }

  inviteUser(data) {
    return axios.post(`${this.base}/invite/`, data).then((res) => res.data);
  }
}


class ArticleService_ extends BaseCRUDService {
  donwloadArticle(id) {
    return axios.get(`${this.base}/${id}/download`, {
      responseType: "blob",
    }).then((res) => res.data);
  }
}
export const PaymentServices = new PaymentService_("payments");
export const StandardService = new StandardCRUDService("standard");
export const SubTitleService = new BaseCRUDService("subtitle");
export const SearchService = new SearchCRUDService("search");
export const ProjectService = new BaseCRUDService("project");
export const FavouriteService = new FavouriteService_("favourite");
export const ArticleService = new ArticleService_("userdoc");
class HelperServices_ {
  submitContactRequest(data) {
    return axios_
      .post(`${baseUrl}/helpers/contact/`, data)
      .then((res) => res.data);
  }
}

export const HelperServices = new HelperServices_();

export const ChatMessageService = new BaseCRUDService("chat_message");

class ChatSessionCRUDService extends BaseCRUDService {
  get_or_create(data) {
    return axios
      .post(`${this.base}/get_or_create/`, data)
      .then((res) => res.data);
  }

  get_messages(id) {
    return axios.get(`${this.base}/${id}/messages/`).then((res) => res.data);
  }

  get_resources(id) {
    return axios.get(`${this.base}/${id}/resources/`).then((res) => res.data);
  }

  clear_session(id) {
    return axios
      .get(`${this.base}/${id}/clear_session/`)
      .then((res) => res.data);
  }

  add_message(id, data) {
    return axios
      .post(`${this.base}/${id}/add_message/`, data)
      .then((res) => res.data);
  }
}

class AuthService_ extends BaseCRUDService {
  login(data) {
    return axios
      .post(`${this.base}/users/token/`, data)
      .then((res) => res.data);
  }

  getAllUsers(params) {
    return axios
      .get(`${this.base}/superadmin/all_users/`, { params: params })
      .then((res) => res.data);
  }

  forgotPasswordInitiate(data) {
    return axios
      .post(`${this.base}/users/forgot_password_initiate/`, data)
      .then((res) => res.data);
  }

  forgotPasswordConfirm(data) {
    return axios
      .post(`${this.base}/users/forgot_password_confirm/`, data)
      .then((res) => res.data);
  }
  resetPassword(data) {
    return axios
      .post(`${this.base}/users/reset_password/`, data)
      .then((res) => res.data);
  }

  confirmForgotPasswordToken(data) {
    return axios
      .post(`${this.base}/users/confirm_forgot_password_token/`, data)
      .then((res) => res.data);
  }

  createInvite(data) {
    return axios
      .post(`${this.base}/users/create_invite/`, data)
      .then((res) => res.data);
  }

  verifyInvite(data) {
    return axios
      .post(`${this.base}/users/verify_invite/`, data)
      .then((res) => res.data);
  }

  finishInvite(data) {
    return axios
      .post(`${this.base}/users/complete_invite/`, data)
      .then((res) => res.data);
  }
}

class BotCRUDService extends BaseCRUDService {
  getDocs(botId, data) {
    return axios
      .get(`${this.base}/${botId}/doc/`, data, {})
      .then((res) => res.data);
  }

  getDocDetail(botId, id, data) {
    return axios
      .get(`${this.base}/${botId}/doc/${id}/`, data, {})
      .then((res) => res.data);
  }

  updateAuthorizedUsers(botId, data) {
    return axios
      .post(`${this.base}/${botId}/update_authorized_users/`, data, {})
      .then((res) => res.data);
  }

  addPublicUrl(botId, data) {
    return axios
      .post(`${this.base}/${botId}/add_public_url/`, data, {})
      .then((res) => res.data);
  }
  uploadDoc(id, data) {
    return axios
      .post(`${this.base}/${id}/upload_file/`, data, {})
      .then((res) => res.data);
  }

  addKB(id, data) {
    return axios
      .post(`${this.base}/${id}/kb/`, data, {})
      .then((res) => res.data);
  }

  updateKB(botId, id, data) {
    return axios
      .put(`${this.base}/${botId}/kb/${id}/`, data, {})
      .then((res) => res.data);
  }

  deleteKB(id, botId, data) {
    return axios
      .delete(`${this.base}/${botId}/kb/${id}/`, data, {})
      .then((res) => res.data);
  }

  getKB(id, botId, data) {
    return axios
      .get(`${this.base}/${botId}/kb/${id}/`, data, {})
      .then((res) => res.data);
  }

  getAllKB(botId, data) {
    return axios
      .get(`${this.base}/${botId}/kb/`, data, {})
      .then((res) => res.data);
  }

  retrieval(botId, data) {
    return axios
      .post(`${this.base}/${botId}/test/retriever/`, data, {})
      .then((res) => res.data);
  }

  clearSession(sessionId) {
    return axios
      .delete(`${this.base}/session/${sessionId}/`)
      .then((res) => res.data);
  }

  getChatbotSession(firstMessage) {
    return axios.get(`${this.base}/get_session/`, 
    {params: {firstMessage: firstMessage}}
    ).then((res) => res.data);
  }

  getSessions() {
    return axios.get(`${this.base}/session/`).then((res) => res.data);
  }

  createChatSession(botId) {
    return axios.post(`${this.base}/session/`).then((res) => res.data);
  }

  getResources(data){
    return axios.post(`${this.base}/resources/`, data).then((res) => res.data);
  }
}


class DocService_ extends BaseCRUDService {

  uploadDoc(data){
    return axios.post(`${this.base}/upload_file/`, data, {})
    .then(res => res.data)
  }

  getArticles(params){
    return axios.get(`${this.base}/articles/`, {params: params})
    .then(res => res.data)
  }
  downloadDoc(id){
    return axios.get(`${this.base}/${id}/download/`, {
      responseType: 'blob'
    }).then(res => res.data)
  }

}

export const getMe = () => {
  return axios.get(`${baseUrl}/auth/users/me/`).then(res => res.data);
}

export const DocService = new DocService_("doc");

export const BotService = new BotCRUDService("chatbot");
export const SessionService = new BotCRUDService("chatbot/session");
export const ChatbotSession = new ChatSessionCRUDService("chatbot/session");
export const AuthService = new AuthService_("auth");
